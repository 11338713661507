const toSite = (pathname: string): string[] => {
    switch (pathname) {
        case "home": return ["Home", "Startseite"];
        case "training": return ["Practice", "Trainingszeiten"];
        case "join": return ["Become a member", "Mitglied werden"];
        case "about": return ["About", "Über uns"];
        case "news": return ["News", "Aktuelles"];
        default: return checkIfNews(pathname);
    }
};

const checkIfNews = (pathname: string): string[] => {
    if (pathname.startsWith("spielbericht")) {
        return ["News", "Spielbericht"];
    }

    if (pathname.startsWith("vorbereitung")) {
        return ["Preperations", "Vorbereitungen"];
    }

    return ["Legal/Imprint", "Impressum"];
};

export const Path = {
    toSite,
}
