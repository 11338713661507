const homepage = ["Home", "Startseite"];
const practice = ["Practice", "Trainingszeiten"]
const practiceReal = ["18+ Tackle/Youth Flag", "Herren/Jugend"]
const member = ["Become a member", "Mitglied werden"]
const about = ["About us", "Über uns"]
const news = ["News", "Aktuelles"]
const close = ["Close", "Menü schließen"]

export const MenuTranslation = {
    homepage,
    practice,
    practiceReal,
    member,
    about,
    news,
    close
};
