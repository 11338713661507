import React, { useEffect } from "react";
import { Header } from "../header/Header";
import styled from "@emotion/styled";
import { History } from "./history/History";

interface AboutProps {
    callback: () => void;
    germanLanguage: boolean;
}

export const About = ({ callback, germanLanguage }: AboutProps) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Header callback={callback} germanLanguage={germanLanguage} />
            <StyledAboutUs>
                <History germanLanguage={germanLanguage} />
            </StyledAboutUs>
        </>
    );
};

const StyledAboutUs = styled.div`
    display: grid;
    margin-top: 2rem;
    gap: 2rem;
`;
