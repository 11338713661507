const heading = ["Our history", "Unsere Geschichte"]

const pt1S1 = [
    "The Ansbach Grizzlies were founded in 1979 with help from US-Army soldiers stationed in Katterbach. Since",
    "Die Ansbach Grizzlies wurden im Jahre 1979 mithilfe der in Katterbach stationierten US-Soldaten gegründet. Seit"
]
const pt1S2 = [
    "we are an officially registered team.",
    "sind wir ein eingetragener Verein."
]
const pt1S3 = [
    "we won against Frankfurt with 27:6 and secured the first German Championship.",
    "wurden wir damals zum ersten Mal Deutscher Meister, mit einem 27:6-Sieg gegen Frankfurt."
]
const pt1S4 = [
    "another win got us to celebrate our second trophy. We won againt the Cologne Crocodiles 12:6 and so we were champions once again.",
    "folgte ein weiterer Meistertitel mit einem 12:6 gegen die Cologne Crocodiles."
]
const pt1S5 = [
    "1983 and 1984 we lost the German Bowl (equivilant to the Super Bowl) against the 'Düsseldorf Panther's. 1985 - however - we won against our greatest competitor and won the German championship. (14:7)",
    "In den Jahren 1983 und 1984 wurden die Ansbach Grizzlies jeweils Vizemeister gegen die Düsseldorf Panther und gegen denselben Gegner 1985 erneut Deutscher Meister.(14:7)"
];

const pt2S1 = [
    "Following a",
    "Nach einem",
]
const pt2S2 = [
    "creation of a new team",
    "Neuaufbau",
]
const pt2S3 = [
    "and the participation in the 'Aufbauliga' for the 2000 season the Ansbach Grizzlies once again played",
    "und Teilnahme an der Aufbauliga im Jahr 2000 nehmen die Ansbach Grizzlies seit",
]
const pt2S4 = [
    " regulary",
    " 2001",
]
const pt2S5 = [
    "in games since",
    "wieder am",
]
const pt2S6 = [
    "2001.",
    "regulären Spielbetrieb",
]
const pt2S7 = [
    "",
    "teil.",
]

const pt2S8 = [
    "While starting in the 'Landesliga Bayern West' the Ansbach Grizzlies got promoted and played in the 'Bayernliga' until the end of the 2006 season, which is the",
    "Zunächst in der Landesliga Bayern West gestartet, spielte das Team bis zum Ende der Saison 2006 in der Bayernliga, welche der"
]
const pt2S9 = [
    "4th highest league in Germany.",
    "4. Liga im Bundesgebiet"
]
const pt2S10 = [
    "",
    "entspricht."
]

const pt2S11 = [
    "Unfortunately, we could not enroll for the 2007 season for regular tackle-football so we instead founded a senior flag-football team.",
    "Für das Jahr 2007 konnte das Herrenteam leider nicht zum Spielbetrieb gemeldet werden und es wurde stattdessen übergangsweise ein Senior Flag-Football-Team gebildet."
]
const pt2S12 = [
    "2008 - however - looked better. We played tackle-football once again in the 'Aufbauliga Bayern A', one of the lowest leagues in Germany. Sadly the Ansbach Grizzlies had to forfeit all games during the season due to a lack of healthy players.",
    "In der Saison 2008 spielte die Mannschaft in der Aufbauliga Bayern A. Jedoch musste die Mannschaft mangels Spieler bald nach Saisonstart wieder aus dem laufenden Spielbetrieb abgemeldet werden."
]

const pt3S1 = [
    "the Ansbach Grizzlies were once again ready and started ",
    "konnten die Grizzlies sich wieder neu aufbauen und starteten"
]
const pt3S2 = [
    "with playing in the 'Aufbauliga Nordbayern' against the Kulmbach Brewers and Schweinfurt Gladiators.",
    "in der Aufbauliga Nordbayern gegen die Kulmbach Brewers und Schweinfurt Gladiators"
]
const pt3S3 = [
    "All of the four games we won without allowing the other team to score ",
    "Alle vier Spiele konnten zu null gewonnen werden "
]
const pt3S4 = [
    "Since then we were active and have won the",
    "Seit dem spielen wir wieder aktiv und konnten die"
]
const pt3S5 = [
    " 2022 season",
    " Season 2022"
]
const pt3S6 = [
    "in the 'Aufbauliga Nord' and subsequently got promoted. You can find our record ",
    "in der Aufbauliga Nord gewinnen und sind somit aufgestiegen. Mehr dazu "
]
const pt3S7 = ["here", "hier"]
const pt3S8 = [
    "Are you interested and want to be part of something awesome?",
    "Du hast auch Lust bekommen?"
]
const pt3S9 = [
    "Just swing by at one of our practices and show us how good you are! We need no prior notice.",
    "Komm einfach bei einem unserer Trainings vorbei und mache ein Tryout mit! Eine Voranmeldung ist hierzu nicht nötig."
]


export const HistoryTranslation = {
    heading,
    pt1S1,
    pt1S2,
    pt1S3,
    pt1S4,
    pt1S5,

    pt2S1,
    pt2S2,
    pt2S3,
    pt2S4,
    pt2S5,
    pt2S6,
    pt2S7,
    pt2S8,
    pt2S9,
    pt2S10,
    pt2S11,
    pt2S12,

    pt3S1,
    pt3S2,
    pt3S3,
    pt3S4,
    pt3S5,
    pt3S6,
    pt3S7,
    pt3S8,
    pt3S9,
};
