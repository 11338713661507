import styled from "@emotion/styled";
import { Header } from "../header/Header";
import { Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import { MemberTranslation } from "../../translation/MemberTranslation";
import { useEffect, useState } from "react";

import Antrag from "../../documents/Mitgliedsantrag_gesamt_DEU_v-2014.pdf";
import AntragEng from "../../documents/Mitgliedsantrag_gesamt_ENG_v-2014-konvertiert -.pdf"

import Play from "../../images/small/rugby.png";
import PAT from "../../images/small/goal.png"
import Football from "../../images/small/american-football.png"

interface MemberProps {
    callback: () => void;
    germanLanguage: boolean;
}

export const Member = ({ callback, germanLanguage }: MemberProps) => {
    const [reduced, setReduced] = useState(false);
    const [halfYearly, setHalfYearly] = useState(false);
    const [checked, setChecked] = useState(true);

    const [value, setValue] = useState('tackle');
    const [grandTotal, setGrandTotal] = useState(0);

    const [month, setMonth] = useState('1');
    const [year, setYear] = useState('2023');

    const [currentMonth, setCurrentMonth] = useState(0);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const d = new Date();
        setCurrentMonth(d.getMonth() + 1);
        setMonth(`${d.getMonth() + 1}`)

        // fade in
        const items = document.querySelectorAll('.appear');

        const active = (entries: any) => {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('inview');
                } else {
                    entry.target.classList.remove('inview');
                }
            });
        }

        const io = new IntersectionObserver(active);
        items.forEach(item => io.observe(item));
    }, []);

    useEffect(() => {
        const newGrandTotal = updateGrandTotal();
        setGrandTotal(newGrandTotal);
    }, [reduced, value])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleMonthChange = (event: SelectChangeEvent) => {
        setMonth(event.target.value as string)
    }

    const handleYearChange = (event: SelectChangeEvent) => {
        setYear(event.target.value as string)
    }


    const updateGrandTotal = (): number => {
        switch (value) {
            case "tackle":
                return reduced ? 108 : 180;
            case "flag":
                return 72;
            default: return 0;
        }
    };

    return (
        <>
            <Header callback={callback} germanLanguage={germanLanguage} />
            <StyledMember className="member">
                <Typography variant="h3" className="college main-heading">{MemberTranslation.become[+germanLanguage]}</Typography>
                <div className="helper-text">
                    <Typography variant="h4" className="helper-heading college">{MemberTranslation.heading[+germanLanguage]}</Typography>
                    <Typography>{MemberTranslation.decide[+germanLanguage]}</Typography>
                    <Typography>{MemberTranslation.interestPt1[+germanLanguage]} <span className="bold">{MemberTranslation.interestPt2[+germanLanguage]}</span> {MemberTranslation.interestPt3[+germanLanguage]} <span className="bold">{MemberTranslation.interestPt4[+germanLanguage]}</span> {MemberTranslation.interestPt5[+germanLanguage]}</Typography> <br />
                    <Typography>{MemberTranslation.comeByPt1[+germanLanguage]} <span className="bold">{MemberTranslation.comeByPt2[+germanLanguage]}</span>. {MemberTranslation.comeByPt3[+germanLanguage]} <span className="bold">3</span> {MemberTranslation.comeByPt4[+germanLanguage]} <span className="bold">{MemberTranslation.comeByPt5[+germanLanguage]}</span> {MemberTranslation.comeByPt6[+germanLanguage]}</Typography> <br />
                    <Typography>{MemberTranslation.readyPt1[+germanLanguage]} <span className="bold">{MemberTranslation.readyPt2[+germanLanguage]}</span> {MemberTranslation.readyPt3[+germanLanguage]} <a className="bold" href={germanLanguage ? Antrag : AntragEng}>{MemberTranslation.readyPt4[+germanLanguage]}</a> {MemberTranslation.readyPt5[+germanLanguage]}. {MemberTranslation.readyPt6[+germanLanguage]} </Typography>
                    <Typography className="quote-text college appear">
                        "Today I will do what others won't, so tomorrow I will accomplish what others can't."
                    </Typography>
                    <Typography className="by appear">-Jerry Rice</Typography>
                </div>
                <Help className="help">
                    <Typography variant="h4" className="helper-heading college">{MemberTranslation.easy[+germanLanguage]}</Typography>
                    <FormControl className="art">
                        <FormLabel className="head">{MemberTranslation.play[+germanLanguage]}</FormLabel>
                        <RadioGroup
                            defaultValue="tackle"
                            value={value}
                            onChange={handleChange}
                        >
                            <FormControlLabel className="test" value="tackle" control={<Radio color="default" />} label={MemberTranslation.tackleF[+germanLanguage]} />
                            <FormControlLabel value="flag" control={<Radio />} label={MemberTranslation.flagF[+germanLanguage]} />
                        </RadioGroup>
                    </FormControl>
                    <FormControl className="erm">
                        <FormLabel className="head">{MemberTranslation.discount[+germanLanguage]}</FormLabel>
                        <FormGroup className="left">
                            <FormControlLabel control={<Switch onChange={() => setReduced(!reduced)} disabled={value == "flag"} />} label={MemberTranslation.yes[+germanLanguage]} />
                        </FormGroup>
                    </FormControl>
                    <FormControl className="abb">
                        <FormLabel className="head">{MemberTranslation.annually[+germanLanguage]}</FormLabel>
                        <FormGroup className="left">
                            <FormControlLabel control={<Switch onChange={() => setHalfYearly(!halfYearly)} />} label={MemberTranslation.yesPlease[+germanLanguage]} />
                        </FormGroup>
                    </FormControl>

                    <div className="grand-total">
                        <Typography gridArea="mb">{MemberTranslation.fees[+germanLanguage]}</Typography>
                        <Typography className="college gt" gridArea="gt">{grandTotal},00 Euro</Typography>
                        <Typography gridArea="pa">{MemberTranslation.year[+germanLanguage]}</Typography>
                        <Typography className="billed" gridArea="a">({MemberTranslation.payed[+germanLanguage]} {halfYearly ? MemberTranslation.twoYearly[+germanLanguage] : MemberTranslation.yearly[+germanLanguage]} {MemberTranslation.as[+germanLanguage]} {halfYearly ? `${MemberTranslation.install[+germanLanguage]} ${grandTotal / 2},00 Euro` : `${grandTotal},00 Euro`})</Typography>
                    </div>

                    <Typography className="erm-helper">* {MemberTranslation.discountWho[+germanLanguage]}</Typography>

                    <div className="hr" />

                    <div className="info">
                        <Typography variant="h4" className="college">{MemberTranslation.tell[+germanLanguage]}</Typography>
                        <TextField className="name" label={MemberTranslation.names[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} />
                        <TextField className="geb" label={MemberTranslation.bday[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} />
                        <div className="fam">
                            <Typography>{MemberTranslation.fam[+germanLanguage]}</Typography>
                            <Chip label={MemberTranslation.ledig[+germanLanguage]} className="chip c1" />
                            <Chip label={MemberTranslation.married[+germanLanguage]} className="chip c2" />
                            <Chip label={MemberTranslation.dead[+germanLanguage]} className="chip c3" />
                            <Chip label={MemberTranslation.div[+germanLanguage]} className="chip c4" />
                        </div>
                        <TextField className="street" label={MemberTranslation.street[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} />
                        <TextField className="ort" label={MemberTranslation.place[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} />
                        <TextField className="plz" label={MemberTranslation.plz[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} />
                        <TextField className="mail" label={MemberTranslation.mail[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} />
                        <TextField className="mobile" label={MemberTranslation.num[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} />
                    </div>

                    <div className="hr hr2" />

                    <div className="fin">
                        <Typography variant="h4" className="college">{MemberTranslation.almost[+germanLanguage]}</Typography>
                        <Typography className="finimp">{MemberTranslation.reminder[+germanLanguage]}</Typography>

                        <FormGroup className="ant">
                            <FormControlLabel control={
                                <Checkbox
                                    checked={checked}
                                    onChange={() => setChecked(!checked)}
                                />
                            } label={MemberTranslation.antrag[+germanLanguage]} />
                        </FormGroup>
                        <TextField className="name" label={MemberTranslation.names[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} disabled={checked} />
                        <TextField className="ort" label={MemberTranslation.place[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} disabled={checked} />
                        <TextField className="plz" label={MemberTranslation.plz[+germanLanguage]} variant="standard" sx={{ input: { color: 'white' } }} disabled={checked} />

                        <TextField className="bank" label="Bank" variant="standard" sx={{ input: { color: 'white' } }} />
                        <TextField className="bic" label="BIC" variant="standard" sx={{ input: { color: 'white' } }} />
                        <TextField className="iban" label="IBAN" variant="standard" sx={{ input: { color: 'white' } }} />
                    </div>

                    <div className="hr hr3" />
                    <div className="get">
                        <Typography variant="h4" className="college">{MemberTranslation.now[+germanLanguage]} </Typography>
                        <Typography>{MemberTranslation.didPt1[+germanLanguage]} <span className="bold">Ansbach Grizzlies</span> {MemberTranslation.didPt2[+germanLanguage]} </Typography>
                        <Typography>{MemberTranslation.what[+germanLanguage]}</Typography>
                        <Typography className="hinweis">{MemberTranslation.note[+germanLanguage]}</Typography>
                        <div className="start">
                            <Typography>{MemberTranslation.start[+germanLanguage]}</Typography>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{MemberTranslation.month[+germanLanguage]}</InputLabel>
                                <Select
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                    value={month}
                                    label="Monat"
                                    onChange={handleMonthChange}
                                    sx={{
                                        height: '2.5rem',
                                        color: 'white',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white'
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: 'white'
                                        },
                                        "&:hover": {
                                            "&& fieldset": {
                                                borderColor: 'white'
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value={1} disabled={currentMonth > 1}>Januar</MenuItem>
                                    <MenuItem value={2} disabled={currentMonth > 2}>Februar</MenuItem>
                                    <MenuItem value={3} disabled={currentMonth > 3}>März</MenuItem>
                                    <MenuItem value={4} disabled={currentMonth > 4}>April</MenuItem>
                                    <MenuItem value={5} disabled={currentMonth > 5}>Mai</MenuItem>
                                    <MenuItem value={6} disabled={currentMonth > 6}>Juni</MenuItem>
                                    <MenuItem value={7} disabled={currentMonth > 7}>Juli</MenuItem>
                                    <MenuItem value={8} disabled={currentMonth > 8}>August</MenuItem>
                                    <MenuItem value={9} disabled={currentMonth > 9}>September</MenuItem>
                                    <MenuItem value={10} disabled={currentMonth > 10}>Oktober</MenuItem>
                                    <MenuItem value={11} disabled={currentMonth > 11}>November</MenuItem>
                                    <MenuItem value={12}>Dezember</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{MemberTranslation.yearMem[+germanLanguage]}</InputLabel>
                                <Select
                                    MenuProps={{
                                        disableScrollLock: true,
                                    }}
                                    value={year}
                                    label="Jahr"
                                    onChange={handleYearChange}
                                    sx={{
                                        height: '2.5rem',
                                        color: 'white',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'white'
                                        },
                                        '& .MuiSvgIcon-root': {
                                            color: 'white'
                                        },
                                        "&:hover": {
                                            "&& fieldset": {
                                                borderColor: 'white'
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem value={2023}>2023</MenuItem>
                                    <MenuItem value={2024}>2024</MenuItem>
                                    <MenuItem value={2025}>2025</MenuItem>
                                    <MenuItem value={2026}>2026</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="go">
                        <Typography variant="h3" className="college">
                            Ready, Set... Hut!
                        </Typography>
                    </div>
                    <Typography className="last">
                        {MemberTranslation.more[+germanLanguage]}<br />
                        {MemberTranslation.just[+germanLanguage]} <a>{MemberTranslation.here[+germanLanguage]}</a>.
                    </Typography>
                </Help>
                <Typography gridArea="done" className="done1 done college">{MemberTranslation.want[+germanLanguage]}</Typography>
                <Typography gridArea="done" className="done2 done college">{MemberTranslation.want[+germanLanguage]}</Typography>
                <Typography gridArea="done" className="done3 done college">{MemberTranslation.want[+germanLanguage]}</Typography>
                <Typography gridArea="done" className="done4 done college">{MemberTranslation.want[+germanLanguage]}</Typography>
                <div className="pictures">
                    <div className="small first appear" />
                    <div className="small second appear" />
                </div>
            </StyledMember>
        </>
    );
};

const StyledMember = styled.div`
    .appear {
        transition: all 0.5s;
        opacity: 0;
        transform: translateY(40px);
    }

    .appear.inview {
        opacity: 0.1;
        transform: rotate(-2deg);
        transition-delay: 0.3s;
    }

    padding-top: 2rem;
    
    display: grid;
    justify-items: center;
    grid-template-areas: "heading heading" "ins help" "done done" "img img";
    grid-template-columns: 1fr 1.25fr;
    grid-template-rows: repeat(4, auto);
    row-gap: 2rem;
    column-gap: 1rem;

    > div.pictures {
        width: 100%;
        height: 50px;
        grid-area: img;
        display: grid;
        justify-items: center;
    }

    > div.pictures > div.small {
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        filter: invert(1);
        z-index: 0;
        width: 10rem;
        height: 10rem;
    }

    > div.pictures > div.small.first {
        background-image: url(${Play});
        margin-top: -100rem;
        margin-left: -55rem;
    }

    > div.pictures > div.small.second {
        background-image: url(${PAT});
        margin-top: -55rem;
        margin-left: -15rem;
        width: 15rem;
        height: 15rem;
    }

    > h3 {
        grid-area: heading;
        margin-bottom: 2rem;
    }

    > div.helper-text {
        display: grid;
        grid-template-rows: repeat(9, fit-content(50px));
        grid-area: ins;
        width: calc(100% - 4rem);
        color: white;
        padding: 2rem;
        height: 25rem;
        align-items: center;
        gap: 1rem;

        > h4.helper-heading {
            font-size: 30px;
            margin-bottom: 2rem;
        }

        > .quote-text {
            margin-top: 27rem;
            text-align: center;
            font-size: 35px;
        }

        > .by {
            justify-self: end;
            margin-top: -2rem;
            margin-right: 2rem;
        }
    }

    > .done {
        font-size: 64px;
        transform: rotate(-2deg);
        margin-top: 5rem;
        text-shadow: 0.4rem 0.4rem rgba(35, 54, 110, 1);
        z-index: 1;
    }

    > .done2 {
        margin-top: 9rem;
        opacity: .25;
    }

    > .done3 {
        margin-top: 13rem;
        opacity: .125;
    }

    > .done4 {
        margin-top: 17rem;
        opacity: .025;
    }
`;

const Help = styled.div`
    z-index: 1;
    background-color: rgb(30, 45, 92);
    color: white;

    width: calc(100% - 4rem);
    height: 156rem;
    grid-area: help;
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    padding: 2rem;

    box-shadow: -0.25rem 0.25rem 0.25rem rgba(0,0,0,.3);

    display: grid;
    grid-template-areas: 
        "heading heading" 
        "art erm" 
        "abb abb"
        "grand-total grand-total" 
        "erm-helper erm-helper"
        "hr hr"
        "info info"
        "hr2 hr2"
        "fin fin"
        "hr3 hr3"
        "get get"
        "go go"
        "last last";

    grid-template-rows: repeat(13, fit-content(200px));
    grid-template-columns: repeat(2, fit-content(350px));
    overflow: hidden;

    justify-items: start;

    gap: 1rem;
    row-gap: 2rem;

    > .last {
        grid-area: last;
        color: rgba(255,255,255,.3) !important;
        font-size: 14px;

        > a {
            color: rgba(255,255,255,.3) !important;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }
        }
    }

    > div.go {
        grid-area: go;
        width: 100%;
        height: 8rem;
        background: #182549;
        display: grid;
        align-items: center;
        border-radius: 0.5rem;
        text-align: center;

        margin-bottom: 2rem;
        margin-top: 2rem;

        > h3 {
            line-height: 1;
            font-size: 55px;
        }

        box-shadow: 0.5rem 1rem 0.75rem rgba(0, 0, 0, .3);
        text-shadow: 0.4rem 0.4rem rgba(35, 54, 110, 1);


        transform: scale(1);
        transition: transform .2s ease-in-out;

        &:hover {
            transform: scale(1.05);
            cursor: pointer;
        }

    }

    > div.get {
        grid-area: get;
        width: 100%;
        display: grid;
        gap: 2rem;

        > h4 {
            font-size: 30px;
        }

        > .hinweis {
            color: rgba(255,255,255,0.3) !important;
            font-size: 14px;
        }

        > div.start {
            width: 100%;
            height: 3rem;
            display: grid;
            grid-template-columns: fit-content(200px) 8rem auto;
            align-items: center;
            gap: 1rem;

            label {
                color: white !important;
            }
        }
    }

    > div.fin {
        grid-area: fin;
        width: 100%;
        display: grid;
        grid-template-areas: "heading heading" "finimp finimp" "ant ant" "name ort" "plz ." "bank bic" "iban iban";
        gap: 2rem;

        > h4 {
            font-size: 30px;
            grid-area: heading;
        }

        > .finimp {
            color: rgba(255, 255, 255, .3) !important;
            grid-area: finimp;
            font-size: 14px;
            margin-top: -1.3rem;
        }

        .MuiFilledInput-root {
            border-bottom: 1px solid white !important;
        }
          
        .MuiInput-root {
            border-bottom: 1px solid white !important;
        }

        label {
            color: white !important;
        }

        .Mui-disabled {
            color: rgba(255,255,255,.3) !important;
        }

        > .ant {
            grid-area: ant;
            margin-left: 0.25rem;
        }
        
        > .name {
            grid-area: name;
        }

        > .ort {
            grid-area: ort;
        }

        > .plz {
            grid-area: plz;
        }

        > .bank {
            grid-area: bank;
        }
        
        > .bic {
            grid-area: bic;
        }

        .iban {
            grid-area: iban;
        }
    }

    > div.info {
        grid-area: info;
        width: calc(100% - 2rem);
        
        background: #182549;
        padding: 1rem 1rem 2rem 1rem;
        border-radius: 0.5rem;

        display: grid;
        gap: 2rem;
        column-gap: 3rem;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
            "heading heading" 
            "name geb"
            "fam fam"
            "street ort"
            "plz ."
            "mail mobile";

        > h4 {
            grid-area: heading;
            font-size: 30px;
        }

        label {
            color: white !important;
        }

        > .name {
            grid-area: name;
        }

        .geb {
            grid-area: geb;
        }

        > .street {
            grid-area: street;
        }

        > .ort {
            grid-area: ort;
        }

        > .plz {
            grid-area: plz;
        }

        > .mail {
            grid-area: mail;
        }

        > .mobile {
            grid-area: mobile;
        }

        > .fam {
            grid-area: fam;
            display: grid;
            grid-template-areas: "heading heading heading heading" "c1 c2 c3 c4";
            grid-template-columns: repeat(4, 1fr);
            gap: 1rem;
            justify-items: center;

            > p {
                justify-self: start;
            }

            > div.chip {
                border: 1px solid white;
                padding: 1rem;
                width: 8rem;

                &:hover {
                    cursor: pointer;
                }
            }

            > div.chip.c1 {
                grid-area: c1;
            }

            > div.chip.c2 {
                grid-area: c2;
            }

            > div.chip.c3 {
                grid-area: c3;
            }

            > div.chip.c4 {
                grid-area: c4;
            }
        }

        .MuiFilledInput-root {
            border-bottom: 1px solid white !important;
        }
          
        .MuiInput-root {
            border-bottom: 1px solid white !important;
        }
    }

    > div.hr {
        grid-area: hr;

        width: 100%;
        height: 0.25rem;

        background:radial-gradient(ellipse at center, white 0%,transparent 70%);
        margin: 0.5rem 0;
    }

    > div.hr2 {
        grid-area: hr2;
    }

    > div.hr3 {
        grid-area: hr3;
    }

    > div.grand-total {
        grid-area: grand-total;
        width: 100%;

        display: grid;
        grid-template-columns: fit-content(200px) 9rem fit-content(200px) fit-content(200px);
        grid-template-rows: auto auto;
        grid-template-areas: "mb gt pa ." ". a a a";
        gap: 1rem;
        row-gap: 0;
        align-items: center;

        > .gt {
            font-size: 26px;
        }

        > .billed {
            font-size: 14px;
            color: rgba(255,255,255, .3) !important;
            grid-area: a;
        }

    }

    > h4 {
        grid-area: heading;
        font-size: 30px;
    }

    .head {
        color: white !important;
        margin-bottom: 1rem;
    }

    > .art {
        grid-area: art;
    }

    > .erm, .abb {
        grid-area: erm;

        > .left {
            margin-left: 0.35rem;
        }
    }

    > .abb {
        grid-area: abb;
    }

    > .erm-helper {
        grid-area: erm-helper;
        color: rgba(255, 255, 255, .3) !important;
        font-size: 14px;
    }
`;