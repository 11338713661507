import styled from "@emotion/styled";
import { Header } from "../header/Header";
import { Welcome } from "./Welcome";
import { OpeningHours } from "./OpeningHours";
import { HistoryShort } from "../about/history/HistoryShort";
import { PictureSeries } from "./picture-series/PictureSeries";
import { useEffect } from "react";
import { ShowUp } from "./ShowUp";
import { UpNext } from "./up-next/UpNext";
import { Typography } from "@mui/material";

interface HomeProps {
    callback: () => void;
    germanLanguage: boolean;
}

export const Home = ({ callback, germanLanguage }: HomeProps) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Wrapper>
            <Header callback={callback} germanLanguage={germanLanguage} />
            <StyledHome className="home">
                <Welcome germanLanguage={germanLanguage} />
                <div className="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                    </svg>
                </div>
                <StyledTwoGrid className="home-two">
                    <Typography className="champYears flashy ophours">'82</Typography>
                    <OpeningHours germanLanguage={germanLanguage} />
                    <HistoryShort germanLanguage={germanLanguage} />
                </StyledTwoGrid>
                <ShowUp />
                <Typography className="champYears flashy upn">'85</Typography>
                <UpNext germanLanguage={germanLanguage} />
            </StyledHome>
        </Wrapper>
    );
};
const Wrapper = styled.div`
`;

const StyledHome = styled.div`
    display: grid;
    grid-template-columns: auto;
    justify-items: center;
    margin-top: 5rem;
    
    grid-template-rows: repeat(5, auto);
    gap: 2rem;

    > .wave {
        width: 100%;
        overflow: hidden;
        line-height: 0;
        margin-top: -2rem;
    }
    
    .wave svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 5rem;
    }
    
    .wave .shape-fill {
        fill: #0f1831;
    }

    .champYears {
        position: absolute;
        z-index: 0;
        font-size: 435px;
        color: rgba(35, 54, 110, .45) !important;
        top: 10rem;
        left: -4rem;
        line-height: .75;
        text-align: center;
        width: 100%;
    }

    .ophours {
        top: 105rem;
        left: -25rem;
    }

    .upn {
        top: 148rem;
        left: 20rem;
    }
`;

const StyledTwoGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-areas: "opening-hours history";
    
    width: 70rem;
    gap: 2rem;
    margin-top: 5rem;
`;
