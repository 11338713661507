import styled from "@emotion/styled";
import { Header } from "../header/Header";
import { Typography } from "@mui/material";
import { NewsType } from "../../types/NewsType";
import { NewsTranslation } from "../../translation/NewsTranslation";
import { NewsPreview } from "./NewsPreview";
import { useEffect } from "react";

interface NewsOverviewProps {
    callback: () => void,
    germanLanguage: boolean,

    content: NewsType[],
}

export const NewsOverview = ({callback, germanLanguage, content}: NewsOverviewProps) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    
    return(
        <>
            <Header callback={callback} germanLanguage={germanLanguage} />
            <StyledNewsOverview className="news-overview">
                <Typography variant="h3" className="heading college" gridArea="heading">{NewsTranslation.heading[+germanLanguage]}</Typography>
                { !+germanLanguage && <Typography variant="caption" gridArea="err" className="err">Please understand that we are not able to provide english translation for each article.</Typography>}
                <div className="news" style={{ gridTemplateRows: `repeat(${content.length}}, auto)`}}>
                    { content.map(el => <NewsPreview key={el.url} content={el} />)}
                </div>
            </StyledNewsOverview>
        </>
    );
}

const StyledNewsOverview = styled.div`
    display: grid;
    justify-items: center;
    margin-top: 2rem;
    grid-template-rows: auto 2rem auto 2rem auto;
    grid-template-areas: "heading" "." "err" "." "news";

    > .err {
        color: rgba(255, 255, 255, .3) !important;
    }

    > div.news {
        grid-area: news;
        width: 100%;

        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;
        row-gap: 3rem;
    }
`;