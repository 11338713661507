const become = ["Become a member", "Mitglied werden"];

// Text left side
const heading = ["You want to become a Grizzly?", "Du willst Mitglied werden?"];
const decide = ["Or are you still trying to decide?", "Oder bist Du noch am überlegen?"];

const interestPt1 = ["At least you", "Dein"];
const interestPt2 = ["seem", "Interesse"];
const interestPt3 = ["to be", "scheint auf jeden Fall"];
const interestPt4 = ["interested", "geweckt zu sein, "]
const interestPt5 = ["otherwise you wouldn't be here on this page, would you?", "sonst wärst Du jetzt nicht hier auf dieser Seite!"]

const comeByPt1 = ["If you first want to try out at one of our practices", "Falls Du erst bei uns mittrainieren willst,"];
const comeByPt2 = ["just swing by", "komm einfach vorbei"]
const comeByPt3 = ["You can have up to", "Wir können Dir bis zu "]
const comeByPt4 = ["(yes -- three!)", "(ja, drei!)"]
const comeByPt5 = ["try outs.", "Probetrainings anbieten."]
const comeByPt6 = ["After that you should have made up your mind.", "Danach solltest Du Dich aber auf jeden Fall entschieden haben."]

const readyPt1 = ["As soon as you", "Sobald Du Dich dazu"]
const readyPt2 = ["are ready to become a Grizzly", "entschieden hast Grizzly zu werden"]
const readyPt3 = ["just download our", "lade Dir einfach unseren"]
const readyPt4 = ["membership application form", "Mitgliedsantrag herunter"]
const readyPt5 = ["and fill it out", "und fülle ihn aus"]
const readyPt6 = ["To make you life a little bit easier you can use the form on this site.", "Alternativ kannst Du auch das Formular auf dieser Seite benutzen."]

// Helper
// First segment
const easy = ["Make your life easy...", "Mach es Dir einfach..."]
const play = ["What do you want to play?", "Was willst Du bei uns spielen?"]
const tackleF = ["Tackle-Football (18+)", "Tackle-Football (Ü18)"]
const flagF = ["Flag-Football (16 and below)", "Flag-Football (U13 und U16)"]
const discount = ["Do you qualify for a discount? *", "Hast Du ein Recht auf Ermäßigung?"]
const yes = ["Yes!", "Ja!"]
const annually = ["Do you want to pay biannually instead of annually?", "Sollen wir den Beitrag halbjährlich anstatt jährlich abbuchen?"]
const yesPlease = ["Yes please!", "Ja, bitte!"]
const fees = ["Membership fees:", "Dein Mitgliedsbeitrag:"]
const year = ["annually", "pro Jahr"]
const payed = ["payed", "abgebucht"]
const yearly = ["annually", "jährlich"]
const twoYearly = ["biannually", "halbjährlich"]
const as = ["as", "als"]
const install = ["two installments of", "zwei mal"]
const discountWho = ["You qualify for a discount if you are in school or actively attending university or if you serve in the Bundeswehr (German Army). Such a certificate must be submitted without being requested.", "Ermäßigungen erhalten Student*innen, Schüler*innen, Auszubildende, als auch Wehr-/Zivildienstleistende. Eine derartige Bescheinigung ist unaufgefordert vorzulegen."]

// Second segment
const tell = ["Tell us about the new Grizzly...", "Erzähle uns vom neuen Grizzly..."]
const names = ["Last and first name", "Name und Vorname"]
const bday = ["Birthday", "Geburtstag"]
const fam = ["Family status", "Familienstatus"]
const ledig = ["Unmarried", "Ledig"]
const married = ["Married", "Verheiratet"]
const dead = ["Widowed", "Verwitwet"]
const div = ["Divorced", "Geschieden"]
const street = ["Street and no.", "Straße und Nr."]
const place = ["Place", "Ort"]
const plz = ["Postal code", "Postleitzahl"]
const mail = ["E-Mail address", "E-Mail Adresse"]
const num = ["Phone number", "Telefonnummer"]

// Third segment
const almost = ["You are almost done...", "Ein paar Kleinigkeiten noch..."]
const reminder = ["Note: if you enter your banking details there will neither be an membership application filled out nor will your details be saved on our servers. These details help us fill out your application form, which you first have to sign for it to be valid.", "Hinweis: Beim Eingeben der Bankverbindung wird weder ein rechtlich bindender Mitgliedsantrag ausgefüllt, noch werden die Daten auf unseren Server zwischengespeichert. Dies dient lediglich dazu, den Vordruck entsprechend der eingegebenen Daten auszufüllen."]
const antrag = ["Applicant is the account holder", "Antragsteller ist Kontoinhaber"]

// Last segment :))))))))))))))))))) (done w life)
const now = ["Become a Grizzly! NOW!", "Werde jetzt ein Grizzly!"]
const didPt1 = ["You did it! We have all the information we need... so if you are sure that you want to play the greatest sport with us -- the ", "Du hast es geschafft! Wir haben alle Informationen, welche wir von Dir benötigen. Wenn Du Dir also sicher bist, dass Du den geilsten Sport bei den"]
const didPt2 = [" -- then complete your application/ registration now! ", "spielen willst, schließe Deine Anmeldung jetzt ab!"]
const what = ["Of you do so an already filled out membership application form will be ready for you to download. This form you simply have to sign brign it to one of our practices!", "Daraufhin wird Dir ein fertig ausgefüllter Mitgliedsantrag zum Download angeboten, welchen Du einfach nur noch ausdrucken und unterschreiben musst. Gerne kannst Du diesen dann zu einem Training mitnehmen und Dich verbindlich anmelden!"]
const note = ["Note: You will need to sign the membership application several times. You agree to our image publication as well as our privacy policy; furthermore, you can also sign up for a family membership. If you are still a minor, your parents must sign for you! Please bring the completely signed membership application with you.", "Hinweis: Auf den Mitgliedsantrag musst Du mehrmals unterschreiben. Du stimmst sowohl unserer Bildveröffentlichung als auch unserer Datenschutzerklärung zu; zudem kannst Du auch eine Familienmitgliedschaft abschließen. Falls Du noch minderjährig bist müssen Deine Eltern für Dich unterschreiben! Bringe den Mitgliedsantrag bitte vollständig unterschrieben mit."]
const start = ["Start of membership:", "Beginn der Mitgliedschaft"]
const month = ["Month", "Monat"]
const yearMem = ["Year", "Jahr"]
const more = ["You just need a little bit more time to think about a membership? No problem! Just download the form and leave the start of your membership blank. You can fill it out manually as soon as you are ready!", "Du brauchst doch noch ein bisschen Bedenkzeit? Kein Problem! Lade Dir den ausgefüllten Mitgliedsantrag einfach ohne Beginn herunter und fülle diesen aus, sobald Du Dir sicher bist!"]
const just = ["Just click", "Klicke dafür einfach"]
const here = ["here", "hier"]

// WE WANT YOU
const want = ["WE WANT YOU! NOW!", "WIR FREUEN UNS AUF DICH!"]


export const MemberTranslation = {
    become,

    heading,
    decide,
    interestPt1,
    interestPt2,
    interestPt3,
    interestPt4,
    interestPt5,
    comeByPt1,
    comeByPt2,
    comeByPt3,
    comeByPt4,
    comeByPt5,
    comeByPt6,
    readyPt1,
    readyPt2,
    readyPt3,
    readyPt4,
    readyPt5,
    readyPt6,

    easy,
    play,
    tackleF,
    flagF,
    discount,
    yes,
    annually,
    yesPlease,
    fees,
    year,
    payed,
    yearly,
    twoYearly,
    as,
    install,
    discountWho,

    tell,
    names,
    bday,
    fam,
    ledig,
    married,
    dead,
    div,
    street,
    place,
    plz,
    mail,
    num,

    almost,
    reminder,
    antrag,

    now,
    didPt1,
    didPt2,
    what,
    note,
    start,
    month,
    yearMem,
    more,
    just,
    here,

    want,
};