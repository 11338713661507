import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import GrizzliesLogo from "../../../images/logos/grizzlies.png";
import { useEffect } from "react";

interface NextGameProps {
    home: boolean,
    homeName: string,
    guestName: string,
    homeScore: number,
    guestScore: number,
    liga: string,
    date: string,
    loc: string,
    unsure: boolean,
    scoreUnsure: boolean,
    className: string,
    pictureImport: string,
    isFlag: boolean,
}


export const NextGame = ({
    home,
    homeName,
    guestName,
    homeScore,
    guestScore,
    className,
    date,
    liga,
    loc,
    unsure,
    scoreUnsure,
    pictureImport,
    isFlag,
}: NextGameProps) => {
    useEffect(() => {
        const scoreHome = document.getElementById("scoreHome");
        const scoreGuest = document.getElementById("scoreGuest");

        if (!scoreUnsure && (homeScore > guestScore)) {
            scoreHome!.classList.add("underlined");
        } else if (!scoreUnsure) {
            scoreGuest!.classList.add("underlined");
        }
    }, []);

    return (
        <StyledNextGame className={className} 
        style={{
            gridTemplateAreas: !isFlag ? 
                '"guest scoreGuest divider scoreHome home" "guestName . . . homeName" "liga liga liga liga liga" "date date loc loc loc"'
            : 
                '"guest scoreGuest divider scoreHome homeName" "guestName . . . homeName" "liga liga liga liga liga" "date date loc loc loc"'
        }}>
            {isFlag && <Typography className="flaggies college">Flaggies!</Typography>}

            { !isFlag && <Typography gridArea="scoreGuest" id="scoreGuest" className="college scoreGuest big">{scoreUnsure ? "-" : guestScore}</Typography> }
            <Typography gridArea="divider" className="college big divider">{isFlag ? "vs." : ":"}</Typography>
            { !isFlag && <Typography gridArea="scoreHome" id="scoreHome" className="college scoreHome big">{scoreUnsure ? "-" : homeScore}</Typography> }

            <Typography gridArea="homeName" className="college homeName" style={{ textAlign: "center" }}>{homeName}</Typography>
            <Typography gridArea="guestName" className="college guestName">{guestName}</Typography>

            {
                !unsure && <Grizzlies style={{ gridArea: `${home ? "home" : "guest"}` }} />
            }
            {
                !unsure && !isFlag && <Against style={{
                    gridArea: `${!home ? "home" : "guest"}`,
                    backgroundImage: `url(${pictureImport})`
                }} />
            }


            {unsure && <Typography>-</Typography>}
            {unsure && <Typography>-</Typography>}
            <Typography gridArea="liga" className="liga">{liga} {scoreUnsure ? `- Score${isFlag ? "s": ""}: tbd.` : ""}</Typography>

            <Typography gridArea="date" className="date">Kickoff: {date}</Typography>
            <Typography gridArea="loc" className="loc">{loc}</Typography>
        </StyledNextGame>
    );
};

const StyledNextGame = styled.div`
    z-index: 1;
    grid-area: up-next;

    display: grid;
    width: 30rem;
    height: 11rem;

    background: #0f1831;
    padding: 2rem;
    border-radius: 0.5rem;

    grid-template-rows: repeat(4, auto);
    grid-template-columns: 1fr 4rem 3rem 4rem 1fr;

    justify-items: center;
    align-items: center;

    > p.flaggies {
        color: darkred !important; 
        position: absolute;
        margin-left: -29rem;
        margin-top: -11rem;
        transform: rotate(-30deg);
        font-size: 32px;
        text-shadow: -1px -1px 0 #ccc, 1px -1px 0 #ccc, -1px 1px 0 #ccc, 1px 1px 0 #ccc;
    }

    > p.big {
        font-size: 42px;
    }

    > p.scoreHome {
        justify-self: start;
    }

    > p.scoreGuest {
        justify-self: end;
    }

    > p.date, p.liga, p.loc {
        justify-self: start;
        font-size: 14px;
        line-height: 1;
    }

    > p.liga {
        margin-top: 1rem;
    }

    > p.loc {
        color: rgba(255, 255, 255, .3) !important;
    }

    > p.underlined {
        text-decoration: underline;
        text-decoration-thickness: 0.2rem;
        text-underline-offset: 0.45rem;
    }
`;

const Grizzlies = styled.div`
    width: 100%;
    height: 100%;
    background-image: url(${GrizzliesLogo});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
`;

const Against = styled.div`
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    filter: grayscale(100%);
`;