import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../header/Header";

interface NewsFullProps {
    imgName: string,
    author: string,
    heading: string,
    text: string,

    callback: () => void,
    germanLanguage: boolean,
};

export const NewsFull = ({imgName, author, heading, text, callback, germanLanguage}: NewsFullProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return(
        <>
            <Header callback={callback} germanLanguage={germanLanguage} />
            <StyledNewsFull className="news">
                { germanLanguage ? 
                <div className="styled-news">
                    <Typography variant="h4" className="heading college" gridArea="heading">{heading}</Typography>
                    <Typography variant="caption" gridArea="author" className="author">verfasst von: {author}</Typography>
                    <Typography 
                        gridArea="text" 
                        style={{whiteSpace: 'pre-line'}} 
                        className="text"
                        dangerouslySetInnerHTML={{ __html: text }} />
                    <Typography variant="caption" className="back" onClick={() => navigate("/news")}>Zu allen News</Typography>
                </div> : <Typography className="error">Unfortunately we are not able to provide an english translation for this article.<br/>Please check back at a later time. Thank you for your understanding.</Typography> }
                
            </StyledNewsFull>
        </>
    );
};

const StyledNewsFull = styled.div`
    display: grid;
    justify-items: center;

    margin-top: 4rem;

    > .error {
        text-align: center;
    }

    > .styled-news {
        display: grid;
        justify-items: center;

        text-align: center;

        width: 60ch;

        grid-template-rows: auto 1rem auto 3rem auto 3rem auto 2rem auto;
        grid-template-areas: "heading" "." "author" "." "image" "." "text" "." "back";

        > .author {
            color: rgba(255, 255, 255, .3) !important;
        }

        > .text {
            text-align: justify !important;
        }

        > .image {
            grid-area: image;
            width: 100%;
            height: 14rem;

            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;

            border-radius: .5rem;
            box-shadow: 0.5rem 1rem 0.75rem rgba(0, 0, 0, .3);
        }

        > .back {
            color: rgba(255, 255, 255, .3) !important;
            text-decoration: underline;
            grid-area: back;
    
            &:hover {
                cursor: pointer;
                color: rgba(255, 255, 255, .5) !important;
            }
        }
`;