import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { GameShort } from "./GameShort";


interface TableProps {
    germanLanguage: boolean,
}

export const Table = ({ germanLanguage }: TableProps) => {
    return (
        <StyledTable className="table">
            <Typography gridArea="away" className="flashy away" variant="h4">Away</Typography>
            <Typography gridArea="vs" className="flashy vs" variant="h5">vs.</Typography>
            <Typography gridArea="home" className="flashy home" variant="h4">Home</Typography>

            
            <GameShort
                gridArea="first"
                awayTeam="Ansbach Grizzlies"
                homeTeam="Ingolstadt Dukes"
                date={germanLanguage ? "✅ 18.05.2024, 15:00 Uhr" : "✅ 05/18/2024, 3:00pm"}
                location="TV Ingolstadt 1861 e.V.m Richard-Wagner-Str. 65"
            />
<GameShort
                gridArea="second"
                awayTeam="Herzogenaurach Rhinos"
                homeTeam="Ansbach Grizzlies"
                date={germanLanguage ? "❌ Abgesagt - Nachholtermin nicht bekannt" : "❌ Cancelled - new date unknown"}
                location="Am Stadion 3, 91522 Ansbach"
            />
<GameShort
                gridArea="third"
                awayTeam="Ansbach Grizzlies"
                homeTeam="Würzburg Panthers"
                date={germanLanguage ? "✅ 09.06.2024, 15:00 Uhr" : "✅ 06/09/2024, 3:00pm"}
                location="Heiner-Dikreiter-Weg 1, 97074 Würzburg"
            />
<GameShort
                gridArea="fourth"
                awayTeam="Ansbach Grizzlies"
                homeTeam="Bamberg Phantoms"
                date={germanLanguage ? "23.06.2024, 15:00 Uhr" : "06/23/2024, 3:00pm"}
                location="TSV Münnerstadt, Am Kleinfeld 1, 97702 Münnerstadt"
            />
<GameShort
                gridArea="fifth"
                awayTeam="Ingolstadt Dukes 2"
                homeTeam="Ansbach Grizzlies"
                date={germanLanguage ? "06.07.2024, 15:00 Uhr" : "07/06/2024, 3:00pm"}
                location="Am Stadion 3, 91522 Ansbach"
            />
<GameShort
                gridArea="sixth"
                awayTeam="Ansbach Grizzlies"
                homeTeam="Herzogenaurach Rhinos"
                date={germanLanguage ? "21.07.2024, 15:00 Uhr" : "07/21/2024, 3:00pm"}
                location="Rhinos Field, Am Weihersbach 4, 91074 Herzogenaurach"
            />
<GameShort
                gridArea="seventh"
                awayTeam="Würzburg Panthers"
                homeTeam="Ansbach Grizzlies"
                date={germanLanguage ? "01.09.2024, 15:00 Uhr" : "09/01/2024, 3:00pm"}
                location="Am Stadion 3, 91522 Ansbach"
            />
<GameShort
                gridArea="eigth"
                awayTeam="Bamberg Phantoms"
                homeTeam="Ansbach Grizzlies"
                date={germanLanguage ? "08.09.2024, 15:00 Uhr" : "09/08/2024, 3:00pm"}
                location="Am Stadion 3, 91522 Ansbach"
            />
        </StyledTable>
    );
};

const StyledTable = styled.div`
    grid-area: table;
    
    margin: 0 10rem;
    margin-top: 4rem;
    padding: 2rem;

    width: calc(100% - 20rem);
    overflow: hidden;

    display: grid;
    grid-template-areas:
        "away vs home"
        "first first first"
		"second second second"
		"third third third"
		"fourth fourth fourth"
		"fifth fifth fifth"
		"sixth sixth sixth"
		"seventh seventh seventh"
		"eigth eigth eigth";

    grid-template-rows: repeat(9, auto);
    grid-template-columns: 1fr 5rem 1fr;
    gap: 2rem;

    justify-items: center;

    > .home {
        justify-self: start;
        transform: rotate(3.5deg);
        font-size: 46px;
    }

    > .away {
        justify-self: end;
        transform: rotate(-6deg);
        font-size: 46px;
    }

    > .vs {
        align-self: center;
    }

    >div:nth-of-type(even) {
        background-color: rgba(35, 54, 110, .55);
    }
`;
