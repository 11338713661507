import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Logo from "../../images/logos/grizzlies.png";
import { LinkTranslation } from "../../translation/LinkTranslation";
import Satzung from "../../documents/Satzung_Ansbach_Grizzlies_DEU_v-2013-06-09.pdf"

import Antrag from "../../documents/Mitgliedsantrag_gesamt_DEU_v-2014.pdf"
import AntragEng from "../../documents/Mitgliedsantrag_gesamt_ENG_v-2014-konvertiert -.pdf"

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface LinksProps {
    germanLanguage: boolean;
}

export const Links = ({ germanLanguage }: LinksProps) => {
    return (
        <StyledLinks className="links">
            <div className="wave">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
                </svg>
            </div>

            <div className="bigLogo logo" />

            <div className="content">
                <Typography className="heading" gridArea="interesting">{LinkTranslation.interesting[+germanLanguage]}</Typography>
                <Typography className="heading stats" gridArea="stats">{LinkTranslation.stats[+germanLanguage]}</Typography>
                <Typography className="heading" gridArea="about">{LinkTranslation.about[+germanLanguage]}</Typography>

                <a href={germanLanguage ? Antrag : AntragEng} style={{ gridArea: "member" }}>{LinkTranslation.member[+germanLanguage]}</a>
                <a href={Satzung} style={{ gridArea: "sat" }}>{LinkTranslation.statutes[+germanLanguage]}</a>
                <a href="https://www.american-footballshop.de/" style={{ gridArea: "afs" }}>American Footballshop</a>

                <a href="https://www.afvby.de/spielbetrieb/herren/landesliga/?Jahr=2023" className="stats" style={{ gridArea: "l23" }}>Landesliga 2023</a>
                <a href="https://ergebnisse.afvby.de/spielbetrieb/Liga/Aufbauliga" className="stats" style={{ gridArea: "a22" }}>Aufbauliga 2022</a>
                <a href="https://www.afvby.de/spielbetrieb/herren/landesliga/?Jahr=2019" className="stats" style={{ gridArea: "l19" }}>Landesliga 2019</a>
                <a href="https://www.afvby.de/spielbetrieb/herren/landesliga/?Jahr=2018" className="stats" style={{ gridArea: "l18" }}>Landesliga 2018</a>

                <a href="https://frankensein.de/american-football-ansbach/" style={{ gridArea: "fas" }}>{LinkTranslation.fas[+germanLanguage]}</a>
                <a href="https://www.youtube.com/watch?v=ha_02oXbN0g" style={{ gridArea: "at" }}>{LinkTranslation.at[+germanLanguage]}</a>
                <a href="https://www.youtube.com/watch?v=1WXTjw0G714" style={{ gridArea: "fakt" }}>{LinkTranslation.facts[+germanLanguage]}</a>
                <a href="https://www.youtube.com/watch?v=bsTcyCfx9d4" style={{ gridArea: "pads" }}>{LinkTranslation.pads[+germanLanguage]}</a>

                <div className="smallLogo logo" />
            </div>

            <ArrowUpwardIcon className="back-up" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
        </StyledLinks >
    );
};

const StyledLinks = styled.div`
    .wave {
        position: absolute;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
        z-index: 1;
    }

    .wave svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 59px;
    }

    .wave .shape-fill {
        fill: #0f1831;
    }

    display: grid;
    width: 100vw;
    height: 20rem;
    background-color: #141414;

    position: relative;
    overflow: hidden;

    div.logo {
        background-image: url(${Logo});
        background-repeat: no-repeat;
        background-size: cover;
    }

    > div.bigLogo {
        position: absolute;
        width: 30rem;
        height: 33rem;
        z-index: 0;

        margin-top: -5rem;
        margin-left: 7rem;
        opacity: .04;
    }

    > .back-up {
        width: 2rem;
        height: 2rem;
        position: absolute;
        bottom: 1rem;
        right: 1.5rem;
        color: rgba(255, 255, 255, .7);

        display: none;

        &:hover {
            cursor: pointer;
        }
    }

    > div.content {
        > div.smallLogo {
            grid-area: logo;
            justify-self: center;

            width: 9.25rem;
            height: 10rem;
            margin-top: 1rem;
            margin-left: 2rem;

            opacity: .75;
        }

        display: grid;
        margin-top: 5rem;  
        margin-bottom: 2rem;
        color: white;
        padding: 0 5rem;

        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 5rem repeat(4, 1.5rem);
        grid-template-areas:
            "interesting stats about logo"
            "member l23 fas logo"
            "sat a22 at logo"
            "afs l19 fakt logo"
            ". l18 pads logo"
            ;

        align-items: center;

        > p, a {
            font-size: 14px;
            z-index: 1;
        }

        > a:not(.heading) {
            color: rgba(255, 255, 255, .6) !important;
            text-decoration: none;

            &: hover {
                cursor: pointer;
                color: white !important;
            }
        }

        > .heading {
            text-decoration: underline;
        }
    }
`