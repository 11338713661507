import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import FavoriteIcon from '@mui/icons-material/Favorite';

export const Copyright = () => {
    return (
        <StyledCopyright>
            <Typography gridArea="made-with" style={{ justifySelf: "end" }}>Made with</Typography>
            <FavoriteIcon style={{ gridArea: "heart" }} className="heart" />
            <Typography gridArea="ansbach" style={{ justifySelf: "start" }}>in Ansbach</Typography>
            <Typography className="cp" onClick={() => window.open("https://janetschel-software.design", '_blank')}>JESd</Typography>
        </StyledCopyright>
    );
}

const StyledCopyright = styled.div`
    background: rgba(13, 13, 13);
    width: 100%;
    height: 2.5rem;

    display: grid;
    grid-template-areas: "made-with heart ansbach ";
    grid-template-columns: 1fr 2rem 1fr;

    justify-items: center;
    align-items: center;

    column-gap: 0.25rem;

    > .heart {
        animation: beat 1s infinite;
    }

    @keyframes beat{
        0% { transform: scale(1); }
        50% { transform: scale(1.175); }
        100% { transform: scale(1); }
    }

    > p {
        font-size: 14px;
        color: rgba(255, 255, 255, .2) !important;
    }

    > .cp {
        position: absolute;
        right: 1rem;
        text-decoration: underline;
        line-height: 2.5rem;
        text-align: center;

        &:hover {
            cursor: pointer;
        }
    }

     svg {
        width: 1.35rem;
        height: 1.35rem;

        > path {
            color: red;
            opacity: .385;
        }
    } 
`;
