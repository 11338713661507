import styled from "@emotion/styled";
import { Typography } from "@mui/material";

interface GameShortProps {
    gridArea: string,
    awayTeam: string,
    homeTeam: string,
    date: string,
    location: string,
}

export const GameShort = ({ gridArea, awayTeam, homeTeam, date, location }: GameShortProps) => {

    return (
        <StyledGameShort style={{ gridArea: `${gridArea}` }}>
            <Typography gridArea="away" className="away college">{awayTeam}</Typography>
            <Typography gridArea="vs" className="college">at</Typography>
            <Typography gridArea="home" className="home college">{homeTeam}</Typography>
            <Typography gridArea="when" className="college when">Kickoff: {date}</Typography>
            <Typography variant="caption" gridArea="where" className="where">{location}</Typography>
        </StyledGameShort>
    );

};

const StyledGameShort = styled.div`
    box-shadow: 0.5rem 1rem 0.75rem rgba(0, 0, 0, .3);

    width: 50rem;
    background-color: rgba(35, 54, 110, 1);
    border-radius: 1rem;

    padding: 1rem 0;

    display: grid;
    grid-template-areas:
        "away vs home"
        "when when when"
        "where where where";

    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 5rem 1fr;
    gap: 1rem;

    justify-items: center;
    align-items: center;

    > .home {
        justify-self: start;
        font-size: 20px;
    }

    > .away {
        justify-self: end;
        font-size: 20px;
    }

    > .where {
        margin-top: -1rem;
        color: rgba(255, 255, 255, .3) !important;
    }


    transform: scale(1);
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.02);
        cursor: pointer;
    }
`;

