const published = ["published", "veröffentlicht"];
const imprint = ["Imprint (GER)", "Impressum"]
const satzung = ["Statutes", "Satzung"]
const mitgliedsantrag = ["Membership", "Mitglieder"]

export const FooterTranslation = {
    published,
    imprint,
    satzung,
    mitgliedsantrag,
};
