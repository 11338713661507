const interesting = ["Interesting", "Interessantes"];
const stats = ["Records", "Statistiken"];
const about = ["About football", "Über Football"];

const member = ["Membership apl. form", "Mitgliedsantrag"]
const statutes = ["View our statutes", "Unsere Satzung ansehen"]

const fas = ["Fascination football", "Faszination Football"];
const at = ["At the Ansbach Grizzlies", "Bei den Ansbach Grizzlies"];
const facts = ["Facts about the Ansbach Grizzlies", "Fakten zu den Ansbach Grizzlies"];
const pads = ["Football equipment", "Ausrüstung beim Football"];

export const LinkTranslation = {
    interesting,
    stats,
    about,

    member,
    statutes,

    fas,
    at,
    facts,
    pads,
};
