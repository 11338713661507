import React, { useState } from "react";
import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { WelcomeTranslation } from "../../translation/WelcomeTranslation";
import Antrag from "../../documents/Mitgliedsantrag_gesamt_DEU_v-2014.pdf"
import { useNavigate } from "react-router-dom";
import { ShowUp } from "./ShowUp";
import { ExpandMore } from "@mui/icons-material";

interface WelcomeProps {
    germanLanguage: boolean;
}

export const Welcome = ({ germanLanguage }: WelcomeProps) => {
    const navigate = useNavigate();
    return (
        <StyledWelcome className="welcome">
            <Typography variant="h3" gridArea="welcome" className="college">{WelcomeTranslation.welcome[+germanLanguage]}</Typography>
            <Typography variant="h4" gridArea="subtitle" className="welcome-subtitle college">{WelcomeTranslation.first[+germanLanguage]}</Typography>

            <Typography className="champYears flashy">'81</Typography>

            <StyledJoin className="button" onClick={() => navigate("/join")}>
                <Typography className="background college">{Array.from(Array(20)).map((_) => `Become the best version of yourself ${"-".repeat(Math.floor(Math.random() * 3) + 1)} `)}</Typography>
                <Typography className="college">{WelcomeTranslation.signup[+germanLanguage]}</Typography>
            </StyledJoin>

            <StyledJoin className="buttonPrac" onClick={() => navigate("/training")}>
                <Typography className="background college">{Array.from(Array(50)).map((_) => `Practice with us! ${"-".repeat(Math.floor(Math.random() * 3) + 1)} `)}</Typography>
                <Typography className="college">{WelcomeTranslation.pracMore[+germanLanguage]}</Typography>
            </StyledJoin>

            <Typography className="champ">{WelcomeTranslation.threeTimes[+germanLanguage]}</Typography>
            <hr className="divider" style={{ color: "white", width: "100%", display: "none" }}></hr>
        </StyledWelcome>
    );
};

const StyledJoin = styled.div`
    grid-area: button;
    width: 27rem;
    height: 6rem;
    background: #182549;
    border-radius: 0.5rem;


    margin-bottom: 5rem;
    margin-top: 3rem;

    display: grid;
    align-items: center;
    justify-items: center;
    > p {
        font-size: 30px;
    }

    box-shadow: 0.5rem 1rem 0.75rem rgba(0, 0, 0, .3);
    text-shadow: 0.4rem 0.4rem rgba(35, 54, 110, 1);

    transform: scale(1);
    transition: transform .2s ease-in-out;

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
    }

    overflow: hidden;

    > .background {
        white-space: pre-line;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 0.075;
        line-height: 1.25;
        font-size: 14px;
        transform: rotate(-4deg);
        margin-left: -3rem;
        margin-top: -2rem;
        width: 150%;
    }

`;

const StyledWelcome = styled.div`
    position: relative;
    display: grid;
    justify-items: center;
    text-align: center;

    background: #0f1831;
    width: 100%;

    padding-top: 5rem;
    margin-top: -5rem;
    padding-bottom: 2rem;

    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
    grid-template-areas: 
        "welcome welcome" 
        "subtitle subtitle"
        "champ champ"
        ". ." 
        "button buttonPrac";

    column-gap: 5rem;

    > h3, h4 {
        z-index: 1;
    }

    

    > .button {
        grid-area: button;
        z-index: 1;
        justify-self: end;
    }

    > .buttonPrac {
        grid-area: buttonPrac;
        justify-self: start;
        z-index: 1;

    }

    > .champ {
        grid-area: champ;
        margin-top: 4rem;
        z-index: 1;
    }
    
}
`;
