import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, BrowserRouter as Router, useNavigate } from "react-router-dom";
import { Home } from './components/home/Home';
import { Training } from "./components/training/Training";
import { About } from "./components/about/About";
import { Imprint } from "./components/imprint/Imprint";
import { Footer } from "./components/footer/Footer";
import { Links } from './components/footer/Links';
import { Member } from './components/member/Member';
import { Copyright } from './components/footer/Copyright';
import { FileUtils } from './utils/file';
import { NewsType } from './types/NewsType';
import { NewsFull } from './components/news/NewsFull';
import { NewsOverview } from './components/news/NewsOverview';

function App() {
    const [germanLanguage, setGermanLanguage] = useState(true);
    const [contents, setContents] = useState<NewsType[]>([]);

    useEffect(() => {
        const value = document.cookie.split("=")[1];
        if (value === undefined) {
            setGermanLanguage(true);
        } else {
            setGermanLanguage(value === "ger")
        }

        /**
         * Routes are generated dynamically based on contents of ./info/info.txt which holds news and Spielbereichte of the
         * Ansbach Grizzlies. I didn't want to add a backend server just for this functionality. It would be costly to implement and host
         * and someone would need to input all the relevant data if a news article is published.
         * Intern I would need some kind of admin interface. For a free project that is way too much overhead so the text file it is.
         * 
         * Text file is structured like this. If there is a formatting error in the contens of the file, the website will fail to generate Routes
         * and content based on it. So it is VERY IMPORTANT to adhere to the given structure:
         * 
         * url;
         * author;
         * heading;
         * text;
         * ::-::-
         * url;
         * author;
         * heading;
         * text;
         * 
         * Two news are seperated by the seperator ::-::-
         * Notice there is no seperator at the end of the file
         * News internal contents are seperated by the seperator ;
         * Notice there is a ; at the end of the last field. It can be omitted.
         * 
         * !! IMPORTANT !!
         * Each URL HAS TO BE unique!
         */

        FileUtils.readFile().then(result => setContents(result));
    }, []);

    const changeTranslationSettings = () => {
        setGermanLanguage(!germanLanguage);

        document.cookie = `language=${!germanLanguage ? "ger" : "us"};`;
    }

    return (
        <Router>
            <>
                <Routes>
                    <Route path="/" element={<Navigate to="/home" />} />
                    <Route path="/home" element={<Home callback={changeTranslationSettings} germanLanguage={germanLanguage} />} />
                    <Route path="/training" element={<Training callback={changeTranslationSettings} germanLanguage={germanLanguage} />} />
                    <Route path="/join" element={<Member callback={changeTranslationSettings} germanLanguage={germanLanguage} />} />
                    <Route path="/news" element={<NewsOverview content={contents} callback={changeTranslationSettings} germanLanguage={germanLanguage} />} />
                    <Route path="/about" element={<About callback={changeTranslationSettings} germanLanguage={germanLanguage} />} />

                    <Route path="/imprint" element={<Imprint callback={changeTranslationSettings} germanLanguage={germanLanguage} />} />

                    { 
                    /* Mapping contents to Routes and URLs does not work like I wan't it to work so we have to manually declare every route that we wan't to have available
                    * on the website.
                    */ }
                    { contents && contents
                        .map(news => 
                            <Route 
                                path={`/news/${news.url}`} 
                                key={news.url}
                                element={
                                    <NewsFull 
                                        key={news.url} 
                                        imgName={news.imgName}
                                        author={news.author} 
                                        heading={news.heading} 
                                        text={news.text}

                                        callback={changeTranslationSettings}
                                        germanLanguage={germanLanguage}
                                    />
                                } 
                            />
                        )
                    }
                </Routes>
                <Footer germanLanguage={germanLanguage} />
                <Links germanLanguage={germanLanguage} />
                <Copyright />
            </>
        </Router>
    );
}

export default App;
