import { Typography } from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { WelcomeTranslation } from "../../../translation/WelcomeTranslation";

interface HistoryShortProps {
    germanLanguage: boolean;
}

export const HistoryShort = ({ germanLanguage }: HistoryShortProps) => {
    return (
        <StyledHistoryShort className="history-short">
            <Typography variant="h4" style={{ marginBottom: "3rem" }} className="college">{WelcomeTranslation.history[+germanLanguage]}</Typography>
            <Typography>
                {WelcomeTranslation.firstSentence[+germanLanguage]} <br /> <br />
                {WelcomeTranslation.secondSentence[+germanLanguage]} <br /> <br />
                {WelcomeTranslation.thirdSentence[+germanLanguage]} <br /> <br />
                {WelcomeTranslation.fourthSentence[+germanLanguage]} <br />
                {WelcomeTranslation.fifthSentence[+germanLanguage]} <Link to="/about" className="more">{WelcomeTranslation.readMore[+germanLanguage]}</Link>
            </Typography>
        </StyledHistoryShort >
    );
};

const StyledHistoryShort = styled.div`
    justify-self: end;
    align-self: start;
    text-align: start;
    grid-area: history;
    
    display: grid;
    width: 60ch;
    
    background-color: #0f1831;
    padding: 2rem;
    border-radius: 2rem;

    transform: scale(1);
    transition: transform 0.2s ease-in-out;

    &:hover {
        transform: scale(1.025);

        cursor: pointer;
    }
    
    > p > .more {
        color: black;
        margin-left: 0.5rem; 
        
        &:hover {
            cursor: pointer;
        }
    }
`;
