import React, { useEffect } from "react";

import AboutUsOne from "../../../images/trophy.jpg";
import AboutUsTwo from "../../../images/helmet.jpg";
import AboutUsThree from "../../../images/lok.jpg";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { TextPart } from "../TextPart";
import { HistoryTranslation } from "../../../translation/HistoryTranslation";

interface HistoryProps {
    germanLanguage: boolean;
}

export const History = ({ germanLanguage }: HistoryProps) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        const items = document.querySelectorAll('.appear');

        const active = (entries: any) => {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('inview');
                }
            });
        }

        const io = new IntersectionObserver(active);
        items.forEach(item => io.observe(item))
    }, []);

    return (
        <StyledHistory>
            <Typography className="heading-history college" variant="h3">{HistoryTranslation.heading[+germanLanguage]}</Typography>
            <TextPart
                text={
                    <span>
                        {HistoryTranslation.pt1S1[+germanLanguage]} <span className="important">1981</span> {HistoryTranslation.pt1S2[+germanLanguage]} <br />
                        <span className="important">1981</span> {HistoryTranslation.pt1S3[+germanLanguage]} <br />
                        <span className="important">1982</span> {HistoryTranslation.pt1S4[+germanLanguage]} <br />
                        {HistoryTranslation.pt1S5[+germanLanguage]}
                    </span>
                }
                textFirst={false}
                pictureImport={AboutUsOne}
            />
            <TextPart
                text={
                    <span>
                        {HistoryTranslation.pt2S1[+germanLanguage]} <span className="important">{HistoryTranslation.pt2S2[+germanLanguage]}</span> {HistoryTranslation.pt2S3[+germanLanguage]}<span className="important">{HistoryTranslation.pt2S4[+germanLanguage]}</span> {HistoryTranslation.pt2S5[+germanLanguage]} <span className="important">{HistoryTranslation.pt2S6[+germanLanguage]}</span> {HistoryTranslation.pt2S7[+germanLanguage]} <br />
                        {HistoryTranslation.pt2S8[+germanLanguage]} <span className="important">{HistoryTranslation.pt2S9[+germanLanguage]}</span> {HistoryTranslation.pt2S10[+germanLanguage]} <br />
                        {HistoryTranslation.pt2S11[+germanLanguage]}<br />
                        {HistoryTranslation.pt2S12[+germanLanguage]}
                    </span>
                }
                textFirst
                pictureImport={AboutUsTwo}
            />
            <TextPart
                text={
                    <span>
                        <span className="important">2013</span> {HistoryTranslation.pt3S1[+germanLanguage]} <span className="important">2014</span> {HistoryTranslation.pt3S2[+germanLanguage]} <br />
                        {HistoryTranslation.pt3S3[+germanLanguage]}<span className="important">(Perfect Season)</span>. <br />
                        {HistoryTranslation.pt3S4[+germanLanguage]}<span className="important">{HistoryTranslation.pt3S5[+germanLanguage]}</span> {HistoryTranslation.pt3S6[+germanLanguage]} <a href="https://www.afvby.de/spielbetrieb/herren/aufbauliga/">{HistoryTranslation.pt3S7[+germanLanguage]}</a>. <br />
                        <span className="important">{HistoryTranslation.pt3S8[+germanLanguage]}</span> {HistoryTranslation.pt3S9[+germanLanguage]}
                    </span>
                }
                textFirst={false}
                pictureImport={AboutUsThree}
            />
        </StyledHistory>
    );
};

const StyledHistory = styled.div`
    margin-top: -2rem;
    padding-top: 2rem;
    
    display: grid;
    grid-template-rows: repeat(4, auto); 
    justify-items: center;
    gap: 2rem;

    .text-part:not(.text-part ~ .text-part) {
        transition-delay: 0s !important;
        transition: all 0s;
    }

    > .text-part.appear {
        transition: all 0.5s;
        opacity: 0;
        transform: translateY(40px);
    }

    > .text-part.appear.inview {
        opacity: 1;
        transform: none;
        transition-delay: 0.3s;
      }
`;
