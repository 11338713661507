const practice = ["Practicehours", "Trainingszeiten"];

const intro = ["We currently have ", "Bei uns trainieren zur Zeit die "];
const introAnd = ["and", "und"];
const tackle = ["Tackle (18+)", "Herren (Ü18)"];
const youth = ["Youth Flag (16 and below)", "Jugend (unter 16)"];
const cheerleading = [
    "If you want to be part of the Ansbach Grizzlies just swing by at one of our practices and show us how good you really are! We need no prior notice.",
    "Hast du auch Lust bekommen? Dann schau einfach bei einem unserer Trainings vorbei und zeige uns, was wirklich in dir steckt! Es ist keine Voranmeldung nötig. Wir trainieren auf dem C-Platz der Spielvereinigung; Totenweg 3 in Ansbach. Für eine genaue Wegbeschreibung schaue bei uns auf Instagram vorbei!"
]
const teams = [" football teams.", "-Football!"]

const tackleWed = ["Wednesday: 7:000 pm - 9:00 pm", "Mittwoch: 19:00 Uhr - 21:00 Uhr"];
const tackleFri = ["Friday: 7:00 pm - 9:00 pm", "Freitag: 19:00 Uhr - 21:00 Uhr"];
const youthWed = ["Wednesday: 5:00 pm - 6:30 pm", "Mittwoch: 17:00 Uhr - 18:30 Uhr"];
const youthFri = ["Friday: 4:30 pm - 6:30 pm", "Freitag: 16:30 Uhr - 18:30 Uhr"];

const noPrac = ["No practice on 11/25/23, 12/09/23, 02/10/24 and 02/24/24.", "An folgenden Tagen kann kein Hallentaining stattfinden: 25.11.2023, 09.12.2023, 10.02.2024 und 24.02.2024. Über kurzfristige Änderungen wird auf unseren Social-Media Seiten informiert!"];


export const PracticeTranslation = {
    practice,
    intro,
    introAnd,
    tackle,
    youth,
    teams,
    cheerleading,
    tackleWed,
    tackleFri,
    youthWed,
    youthFri,
    noPrac,
};
