import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { NextGame } from "./Game";

// Logos from other Football
import NMWolves from "../../../images/teams/NM-Wolves_Logo.png";
import RMOS from "../../../images/teams/rmos.png";
import HerzoRhinos from "../../../images/teams/Herzo_Rhinos_Logo.png";
import Spiegelau from "../../../images/teams/spiegelau_bats.jpg";
import Maniacs from "../../../images/teams/maniacs.png";
import Dukes from "../../../images/teams/IMG_0170.png";
import Panthers from "../../../images/teams/panthers.png";
import Phantoms from "../../../images/teams/bamberg-phantoms.png";
import { GamesTranslation } from "../../../translation/GamesTranslation";
import { Table } from "./Table";

interface UpNextProps {
    germanLanguage: boolean,
}

export const UpNext = ({ germanLanguage }: UpNextProps) => {
    return (
        <StyledUpNext className="up-next">
            <Typography variant="h3" className="heading college" gridArea="heading">{GamesTranslation.heading[+germanLanguage]}</Typography>
            <NextGame
                className="tackleLast"
                home={false}
                homeName="Würzburg Panthers"
                guestName="Ansbach Grizzlies"
                homeScore={26}
                guestScore={15}
                unsure={false}
                scoreUnsure={false}

                pictureImport={Panthers}

                date={germanLanguage ? "07.06.2024, 15:00 Uhr" : "06/07/24, 3:00 pm"}
                loc="Heinrich-Dikreiter-Weg 1, Würzburg"
                liga="Punktespiel 2024"

                isFlag={false}
            />
			<NextGame
                className="tackleNext"
                home={false}
                homeName="Bamberg Phantoms"
                guestName="Ansbach Grizzlies"
                homeScore={0}
                guestScore={0}
                unsure={false}
                scoreUnsure

                pictureImport={Phantoms}

                date={germanLanguage ? "23.06.2024, 15:00 Uhr" : "06/23/24, 3:00 pm"}
                loc="Sportzentrum Münnerstadt, Münnerstadt"
                liga="Interconference-Game 2024"

                isFlag={false}
            />
            <NextGame
                className="youthNext"
                home={false}
                homeName="Neustadt Falcons & Aschaffenburg Stallions"
                guestName="Grizzlies Jugend"
                homeScore={0}
                guestScore={0}
                unsure={false}
                scoreUnsure

                pictureImport={Phantoms}

                date={germanLanguage ? "23.06.2024, 10:00 Uhr" : "06/23/24, 10:00 am"}
                loc="An der weißen Marter, Neustadt/Aisch"
                liga="U13 Flag Turnier"

                isFlag
            />
            <Table germanLanguage={germanLanguage} />
            <Typography className="more" gridArea="more">{GamesTranslation.more[+germanLanguage]} <a href="https://afvby.de">afvby.de</a>.</Typography>
        </StyledUpNext>
    );
};

const StyledUpNext = styled.div`
    margin-top: 7rem;
    display: grid;

    width: calc(100% - 10rem);

    grid-template-areas: 
        "heading heading" 
        "tackleLast tackleNext"
        "youthNext youthNext"
        "table table"
        "more more";

    grid-template-rows: repeat(5, auto);
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    justify-items: center;

    > .tackleLast {
        grid-area: tackleLast;
        justify-self: end;
    }

    > .tackleNext {
        grid-area: tackleNext;
        justify-self: start;
    }

    > .youthNext {
        grid-area: youthNext;
        justify-self: center;
    }

    h3 {
        z-index: 1;
    }

    > p.more {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.3) !important;

        width: 30ch;
        text-align: center;

        > a {
            color: rgba(255, 255, 255, 0.3) !important;
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }
        }
    }
`;
