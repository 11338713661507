import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ShowUp = () => {
    const navigate = useNavigate();

    return (
        <StyledShowUp className="member-banner" onClick={() => navigate("/join")}>
            <Typography variant="h4" className="up college">Show up 'n'</Typography>
            <Typography variant="h4" className="out college">Show out</Typography>
            <Typography className="bg college">{Array.from(Array(150)).map((_) => `Show up 'n' show out ${"-".repeat(Math.floor(Math.random() * 3) + 1)} `)}</Typography>
        </StyledShowUp>
    );
};

const StyledShowUp = styled.div`
    display: grid;
    margin-top: 5rem;
    width: calc(100% + 2rem);
    transform: rotate(-3deg);

    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: "up out";

    color: white;
    background: rgba(20, 20, 20, .7);
    height: 9.75rem;
    box-shadow: 0.5rem 1rem 0.75rem rgba(0, 0, 0, .3);

    overflow: hidden;

    &:hover {
        cursor: pointer;
    }

    > h4 {
        font-size: 8rem;
        padding-left: 2rem;
        text-shadow: 0.4rem 0.4rem rgba(35, 54, 110, 1);
        z-index: 1;
    }

    > h4.up {
        grid-area: up;
        justify-self: end;
    }

    > h4.out {
        grid-area: out;
    }

    > .bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        opacity: 0.15;
        line-height: 1.25
    }
`;