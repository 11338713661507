import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import React, { useEffect } from "react";
import { PracticeTranslation } from "../../translation/PracticeTranslation";

interface OpeningHoursProps {
    germanLanguage: boolean;
}

export const OpeningHours = ({ germanLanguage }: OpeningHoursProps) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <StyledOpeningHours className="opening-hours">
            <Typography variant="h3" gridArea="heading" className="college">{PracticeTranslation.practice[+germanLanguage]}</Typography>
            <Typography gridArea="important" textAlign="center" margin="0 2rem">
                {PracticeTranslation.intro[+germanLanguage]} <span className="important">{PracticeTranslation.tackle[+germanLanguage]}</span> {PracticeTranslation.introAnd[+germanLanguage]} <span className="important">{PracticeTranslation.youth[+germanLanguage]}</span>{PracticeTranslation.teams[+germanLanguage]} <br />
                {PracticeTranslation.cheerleading[+germanLanguage]}
            </Typography>
            <Typography variant="h5" gridArea="herren" style={{ marginTop: "3rem" }}>
                {PracticeTranslation.tackle[+germanLanguage]}
            </Typography>
            {reformatText(PracticeTranslation.tackleWed[+germanLanguage], 1, "")}
            {reformatText(PracticeTranslation.tackleFri[+germanLanguage], 2, "")}

            <Typography variant="h5" gridArea="youth" style={{ marginTop: "3rem" }}>
                {PracticeTranslation.youth[+germanLanguage]}
            </Typography>
            {reformatText(PracticeTranslation.youthWed[+germanLanguage], 3, "")}
            {reformatText(PracticeTranslation.youthFri[+germanLanguage], 4, "")}

            <Typography gridArea="contactHeader" variant="h6">Kontakt</Typography>
                    <Typography gridArea="mail" textAlign="center">
                        Headcoach Herren/Tackle: HC Mischa<br />
                        <a href="mailto:seniors.hc@ansbach-grizzlies.com">seniors.hc@ansbach-grizzlies.com</a> <br /><br />

                        Abteilungsleiter Herren/Tackle: Julia Friedlein<br />
                        <a href="mailto:abteilungsleiter.herren@ansbach-grizzlies.com">abteilungsleiter.herren@ansbach-grizzlies.com</a> <br /><br />
                        
                        Headcoach Jugend/Flag: Coach Jan<br />
                        <a href="mailto:jugend@ansbach-grizzlies.com">jugend@ansbach-grizzlies.com</a> <br />
                    </Typography>
        </StyledOpeningHours >
    );
};

const reformatText = (weekday: string, index: number, youth: string): JSX.Element => {
    const split = weekday.split(": ");

    return (
        <React.Fragment key={index}>
            <Typography gridArea={`day${index}`} className="day" justifySelf="end">{split[0]}:</Typography>
            <Typography justifySelf="start">{split[1] === "Geschlossen" ? "Ruhetag" : split[1].replaceAll("–", " – ")}</Typography>
        </React.Fragment>
    );
};

const StyledOpeningHours = styled.div`
    z-index: 1;
    grid-area: opening-hours;
    display: grid;
    grid-template-rows: auto auto auto auto auto auto auto 3rem auto;
    grid-template-columns: auto auto;
    grid-template-areas: "heading heading" 
                        "important important"
                        "herren herren"
                        "day1 ."
                        "day2 ."
                        "youth youth"
                        "day3 ."
                        "day4 ."
                        "nothing nothing"
                        "nothingTwo nothingTwo"
                        "contactHeader contactHeader"
                        "mail mail";
                         
     justify-items: center;
    
    gap: 0.5rem;
    margin-bottom: 1rem;
    
    > .day {
        color: gray;
    }

    > p > span.important {
        font-weight: bold !important;
    }
`;
