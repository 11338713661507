const welcome = ["We are the Ansbach Grizzlies!", "Willkommen bei den Ansbach Grizzlies!"]
const first = ["First and Real Ansbach American Football Team Since 1981.", "First and Real Ansbach American Football Team, Seit 1981."]
const signup = ["JOIN US NOW", "JETZT GRIZZLY WERDEN"]
const pracMore = ["Practice with us", "Trainiere mit uns"]
const threeTimes = ["3-times German champions, and still active to this day! 🏆", "3-facher Deutscher Meister in Football, und immer noch aktiv! 🏆"]
const our = ["Our", "Unsere"]
const tackle = ["tackle-team", "Herren"]
const prac = ["practices every", "trainieren jeden"]
const when = ["wednesday 7pm to 9pm and friday between 8:30pm and 10:30pm", "Mittwoch zwischen 19:00 Uhr bis 21:00 Uhr und Freitag zwischen 20:30 Uhr und 22:30 Uhr"]
const where = ["at the 'C-Platz' of the Ansbach soccer club; the", "auf dem C-Platz der SpVgg Ansbach, unsere"]
const youth = ["youth-team", "Jugend"]
const whenYouth = ["saturdays between 4pm and 6:30pm", "samstags zwischen 16:00 Uhr und 18:30 Uhr"]
const whereYouth = ["indoors. Check the map below and toggle between the different practice locations.", "in der Halle des Carolinum Gymnasiums in Ansbach."]
const bePart = ["Wanna be part of something awesome? Just try yourself out at one of our practices!", "Hast auch Du Lust Teil eines großen, neuen Teams zu werden? Schau einfach bei unserem Training vorbei und mache eine Try-Out mit!"]

const location = ["Locations", "Unsere Standorte"];

const youthToggleOne = ["Youth", "Jugend"]
const youthToggleTwo = [" indoors", "halle"]

const tackleToggleOne = ["Tackle", "Herren"]
const tackleToggleTwo = [" outdoors", "platz"]

const history = ["Footballteam with history", "Ein Footballteam mit Geschichte"]
const firstSentence = [
    "Stationed US-Army personell helped with founding the Ansbach Grizzlies in 1979. We truly are rooted in Katterbach. ",
    "Die Ansbach Grizzlies wurden im Jahre 1979 mithilfe der in Katterbach stationierten US-Soldaten gegründet."
]
const secondSentence = [
    "As one of the first American Football clubs in Germany and one of the founding members of the GFL - the German Football League - we already managed to win the trophy 3 times!",
    "Als einer der Mitbegründer der Deutschen Footballbundesliga gelang es den Ansbach Grizzlies bereits 3 mal den Titel zu gewinnen! "
]
const thirdSentence = [
    "1983 and 1984 we lost the German Bowl (equivilant to the Super Bowl) against the 'Düsseldorf Panther's. 1985 - however - we won against our greatest competitor and won the German championship. (14:7) ",
    "In den Jahren 1983 und 1984 wurden die Ansbach Grizzlies jeweils Vizemeister gegen die Düsseldorf Panther und gegen denselben Gegner 1985 erneut Deutscher Meister. (14:7) "
]
const fourthSentence = [
    "The Ansbach Grizzlies won the 'Aufbauliga Nord' - one of our leagues - in 2022 and subsequently promoted to the 'Landesliga' for the upcoming 2023 season.",
    "2022 gelang es den Ansbach Grizzlies die Aufbauliga Nord für sich zu entscheiden und somit in die Landesliga für die Saison 2023 aufzusteigen. "
]
const fifthSentence = [
    "We are all excited...",
    "Die Saison 2023 steht bereits in Ihren Startlöchern..."
]
const readMore = ["Read more", "Mehr lesen"]

export const WelcomeTranslation = {
    welcome,
    first,
    signup,

    threeTimes,
    our,
    tackle,
    prac,
    when,
    where,
    youth,
    whenYouth,
    whereYouth,
    bePart,
    youthToggleOne,
    youthToggleTwo,
    tackleToggleOne,
    tackleToggleTwo,


    pracMore,

    location,

    history,
    firstSentence,
    secondSentence,
    thirdSentence,
    fourthSentence,
    fifthSentence,
    readMore,
};