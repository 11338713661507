import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";

import BackgroundImage from "../../images/party.jpg"
import GrizzliesLogo from "../../images/logos/grizzlies.png"
import { Link, useNavigate } from "react-router-dom";
import { Menu, MenuOpen } from "@mui/icons-material";
import { Path } from "../../utils/pathname";

import Germany from "../../images/logos/germany.png"
import USA from "../../images/logos/united-states.png"
import { MenuTranslation } from "../../translation/MenuTranslation";
import { FooterTranslation } from "../../translation/FooterTranslation";
import Logo from "../../images/logos/grizzlies.png";

interface HeaderProptypes {
    callback: () => void;
    germanLanguage: boolean;
}

export const Header = ({ callback, germanLanguage }: HeaderProptypes) => {
    const [fontSize] = useState(96);
    const [currentlyShown, setCurrentlyShown] = useState("");
    const [actions, setActions] = useState<HTMLCollectionOf<Element>>();

    const [isMenuOpen, setMenuOpen] = useState(false);

    const navigate = useNavigate();

    const getCurrentLocation = (): string => {
        return window.location.pathname
            .split("/")[1]
            .split("?")[0]
            .split("%3F")[0];
    }

    useEffect(() => {
        // Split on every possible combination of url parts
        const currentLocation = getCurrentLocation();
        setCurrentlyShown(Path.toSite(currentLocation)[+germanLanguage]);

        const mobileMenuActions = document.getElementsByClassName("MobileMenuBar");
        setActions(mobileMenuActions);
    }, []);

    useEffect(() => {
        if (currentlyShown === "")
            return;

        const elements = Array.prototype.slice.call(actions);

        elements.forEach(action => action.classList.remove("selected"));
        const item = elements
            .filter(action => action.classList.contains(`MobileMenuBar-${currentlyShown}`))[0];

        if (item) {
            item.classList.add("selected");
        }

    }, [currentlyShown]);

    useEffect(() => {
        const currentLocation = getCurrentLocation();
        setCurrentlyShown(Path.toSite(currentLocation)[+germanLanguage]);
    }, [germanLanguage]);

    useEffect(() => {
        const htmlStyle = document.documentElement.style;
        const menuBarMobile = document.getElementById("menu-bar-mobile");
        const menuBarMobileBackground = document.getElementById("menu-bar-mobile-background");

        if (isMenuOpen && menuBarMobile && menuBarMobileBackground) {
            htmlStyle.overflow = "hidden";
            menuBarMobile.classList.add("shown");
            menuBarMobileBackground.classList.add("shown-background");
        } else if (menuBarMobile && menuBarMobileBackground) {
            htmlStyle.overflow = "";
            menuBarMobile.classList.remove("shown");
            menuBarMobileBackground.classList.remove("shown-background");
        }
    }, [isMenuOpen]);

    const handleClick = () => {
        setMenuOpen(!isMenuOpen);
    };

    const changeTranslationSettings = () => {
        callback();
    };

    return (
        <>
            <StyledWelcomeImage className="header-image" onClick={() => navigate("/home")}>
                <Typography className="heading college" fontSize={fontSize + 32} color="white">Ansbach</Typography>
                <Typography className="sub-heading college" fontSize={fontSize} color="white">Grizzlies</Typography>
                <div className="grizzlies"></div>
            </StyledWelcomeImage>
            <MenuBar id="menu-bar">
                <Menu className="menu-bar-icon-mobile" onClick={handleClick} style={{ color: "white" }} />
                <MenuIcon className="menu-bar-mobile-translate" style={{ backgroundImage: `url(${germanLanguage ? Germany : USA})` }} onClick={changeTranslationSettings} />
                <Typography className="currently-shown flashy" style={{ color: "white" }} >{currentlyShown}</Typography>

                <Link to="/home" className="flashy">{MenuTranslation.homepage[+germanLanguage]}</Link>
                <Link to="/training" className="flashy">{MenuTranslation.practice[+germanLanguage]}</Link>
                <Link to="/join" className="flashy">{MenuTranslation.member[+germanLanguage]}</Link>
                <Link to="/about" className="flashy">{MenuTranslation.about[+germanLanguage]}</Link>
                <Link to="/news" className="flashy">{MenuTranslation.news[+germanLanguage]}</Link>
            </MenuBar>

            <StyledBackground id="menu-bar-mobile-background" />
            <StyledMenuBarMobile id="menu-bar-mobile" className="menu-bar-mobile">
                <div className="actual-menu" >
                    <div className="close">
                        <MenuOpen className="menu-bar-icon-mobile" onClick={handleClick} fontSize="large" style={{ color: "white" }} />
                        <Typography onClick={handleClick} style={{ color: "white" }} className="flashy">{MenuTranslation.close[+germanLanguage]}</Typography>
                    </div>
                    <div className="menu" onClick={handleClick}>
                        <Link to="/home" className="MobileMenuBar-Home MobileMenuBar flashy">{MenuTranslation.homepage[+germanLanguage]}</Link>
                        <Link to="/training" className="MobileMenuBar-Practice MobileMenuBar flashy">
                            <span style={{ color: "white" }} className="flashy">
                                {MenuTranslation.practice[+germanLanguage]} <br />
                                <span style={{ marginLeft: "1rem", color: "white" }} className="flashy">{MenuTranslation.practiceReal[+germanLanguage]}</span>
                            </span>
                        </Link>
                        <Link to="/join" className="MobileMenuBar-Join MobileMenuBar flashy">{MenuTranslation.member[+germanLanguage]}</Link>
                        <Link to="/about" className="MobileMenuBar-About MobileMenuBar flashy">{MenuTranslation.about[+germanLanguage]}</Link>
                        <Link to="/news" className="MobileMenuBar-News MobileMenuBar flashy">{MenuTranslation.news[+germanLanguage]}</Link>
                    </div>
                </div>
                <div className="logo" />
            </StyledMenuBarMobile >
        </>
    );
};

const StyledWelcomeImage = styled.div`
    display: flex;
    justify-content: center;
    
    width: 100vw;
    height: 25rem;
    
    color: white;
    background: 
        linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.45)), 
        url(${BackgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 40%;
    
    overflow: hidden;
    
    > p:first-of-type {
        margin-top: 3rem;
        z-index: 1;
    }
    
    > p:nth-of-type(2n) {
        position: absolute;
        margin-top: 11rem;
        z-index: 1;
    }

    > div.grizzlies {
        position: absolute;
        width: 18.5rem;
        height: 20rem;
        margin-top: 2rem;
        background-image: url(${GrizzliesLogo});
        background-repeat: no-repeat;
        background-size: contain;
        opacity: .35;
    }
`;

const MenuBar = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;
    color: white !important;

    display: grid;
    grid-template-columns: repeat(5, auto) 5rem;
    grid-column-start: 2;
    justify-items: center;
    align-items: center;
    
    width: 100vw;
    height: 3rem;
    background: rgba(35, 54, 110, 1);

    > a {
        text-decoration: none;
        font-size: 20px;
        
        &:hover {
            text-decoration: underline;
        }
    }

    > p.currently-shown {
        display: none;
        grid-area: currently-shown;
        font-size: 20px !important;
    }
    
    > svg.menu-bar-icon-mobile {
        display: none;
        margin-left: 1rem;
        grid-area: menu-bar-icon-mobile;
        
        &:hover {
            cursor: pointer;
        }
    }
    
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
`;

const StyledBackground = styled.div`
    position: fixed;
    top: 0;
    
    z-index: 3;
    
    height: 100vh;
    width: 100vw;
    
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    
    pointer-events: none;
    
    background: rgba(0, 0, 0, 0.725);
`;

const StyledMenuBarMobile = styled.div`
    position: fixed;

    top: 0;
    
    z-index: 3;
    
    height: 100vh;
    width: 100vw;
    
    transition: margin 0.3s ease-in-out;
    
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-template-areas: "actual .";
    margin-left: -100vw;
    color: white !important;

    > div.logo {
        position: absolute;
        width: 30rem;
        height: 32rem;
        background-image: url(${Logo});
        background-repeat: no-repeat;
        background-size: cover;
        bottom: -5rem;
        left: -13rem;
        opacity: .15;
        z-index: 0;
    }

    > div.actual-menu {
        color: white !important;
        grid-area: actual;
        background: rgba(35, 54, 110, 1);
        
        display: grid;
        grid-template-rows: 5rem auto;
        grid-template-areas: "close";

        box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, .75);

        > div.close {
            grid-area: close;
            padding-left: 1rem;
            color: white !important;

            > p {
            font-size: 20px !important;

            }            
            display: grid;
            grid-template-columns: 2rem auto;
            gap: 1rem;
            align-items: center;
            border-bottom-right-radius: 3rem 1.25rem;
            
            &:hover {
                cursor: pointer;
            }
            
            > p:hover {
                text-decoration: underline;
            }

            background: #0f1831;
            box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, .3);
        }
        
        > div.menu {
            padding-left: 1rem;
            margin-top: 1.5rem;

            display: grid;
            grid-template-rows: repeat(6, fit-content(5rem));
            gap: 1rem;

            > .flashy {
                font-size: 20px !important;
            }

            > a.selected {
                border: 1px solid white;
            }
            
            > a {
                text-decoration: none;
                background: rgba(15, 24, 49, .45);

                padding: 1rem;
                border-radius: 1rem;
                margin-right: 2rem;

                border: 1px solid transparent;

                z-index: 1;
                box-shadow: 0.5rem 0.5rem 0.75rem rgba(0, 0, 0, .3);
            }
        }
    }
`;

const MenuIcon = styled.div`
    width: 2.5rem;
    height: calc(100% - 1.5rem);
    justify-self: end;
    position: absolute;
    margin-right: 0.5rem;

    background-repeat: no-repeat;
    background-size: contain;

    &:hover {
        cursor:pointer;
    }
`;
