import React from "react";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Shop } from "../home/Shop";

import Instagram from "../../images/logos/instagram.png"
import Facebook from "../../images/logos/facebook.png"
import Music from "../../images/logos/headphones.png"
import AFS from "../../images/logos/american-footballshop.png"
import { FooterTranslation } from "../../translation/FooterTranslation";
import Satzung from "../../documents/Satzung_Ansbach_Grizzlies_DEU_v-2013-06-09.pdf"
import Antrag from "../../documents/Mitgliedsantrag_gesamt_DEU_v-2014.pdf"

interface FooterProps {
    germanLanguage: boolean;
}

export const Footer = ({ germanLanguage }: FooterProps) => {
    const openInstagram = () => {
        window.open("https://www.instagram.com/ansbach_grizzlies/", '_blank');
    };

    const openFacebook = () => {
        window.open("https://www.facebook.com/AnsbachGrizzlies/", '_blank');
    };

    const openMusic = () => {
        window.open("https://www.youtube.com/watch?v=njpS7u-jH9k", '_blank');
    };

    return (
        <StyledFooter className="footer">
            <div className="wave">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" className="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" className="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill"></path>
                </svg>
            </div>
            <div className="content">
                <Typography className="sl college">DONT BE A BEAR, BE A GRIZZLY! </Typography>
                <Typography className="cr">Ansbach Grizzlies 1981 e.V., {FooterTranslation.published[+germanLanguage]} 2022</Typography>
                <Link to="/imprint" className="imprint">{FooterTranslation.imprint[+germanLanguage]}</Link>
                <a href={Satzung} className="satzung">{FooterTranslation.satzung[+germanLanguage]}</a>
                <a href={Antrag} className="mitgliedsantrag">{FooterTranslation.mitgliedsantrag[+germanLanguage]}</a>
                <div className="i" onClick={openInstagram}></div>
                <div className="fb" onClick={openFacebook}></div>
                <div className="music" onClick={openMusic}></div>
            </div>
        </StyledFooter >
    );
};

const StyledFooter = styled.div`
    > .wave {
        position: absolute;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }


    > .wave svg {
        position: relative;
        display: block;
        width: calc(159% + 1.3px);
        height: 150px;
    }

    > .wave .shape-fill {
        fill: #182549;
    }

    background: #0f1831;

    display: grid;
    margin-top: 5rem;

    bottom: 0;

    > .content {
        display: grid;
        grid-template-rows: auto auto auto 1rem auto;
        grid-template-columns: auto 3rem 1rem 3rem auto;
        grid-template-areas: "sl sl sl sl sl" "cr cr cr cr cr" "imprint . satzung . mitglied" ". .  . . ." "i i m fb fb";
        justify-items: center;
        gap: 0rem 1rem;
        
        padding-bottom: 3rem;
        margin-top: 7rem;
        
        > p, a {
            font-size: 14px;
            color: black;
        }
        
        > .shop {
            grid-area: shop;
        }

        > .cr {
            grid-area: cr;
        }

        > .sl {
            grid-area: sl;
            font-size: 20px;
        }
        
        > .imprint {
            grid-area: imprint;
            justify-self: end;
        }
        
        > .site-admin {
            grid-area: login;
            justify-self: start;
        }

        > .i, .fb, .music {
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: contain;
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);

            &: hover {
                cursor: pointer;
            }
        }

        > .i {
            grid-area: i;
            justify-self: end;
            background-image: url(${Instagram});
            margin-right: .5rem;
        }

        > .fb {
            grid-area: fb;
            justify-self: start;
            background-image: url(${Facebook});
            margin-left: .5rem;
        }

        > .music {
            grid-area: m;
            justify-self: center;
            background-image: url(${Music});
        }

        > .satzung {
            grid-area: satzung;
        }

        > .mitgliedsantrag {
            grid-area: mitglied;
            justify-self: start;
        }
    }

`;
