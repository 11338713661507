import { Header } from "../header/Header";
import styled from "@emotion/styled";
import { Accordion, AccordionDetails, AccordionSummary, Stack, Switch, Typography } from "@mui/material";
import { OpeningHours } from "../home/OpeningHours";
import { WelcomeTranslation } from "../../translation/WelcomeTranslation";
import { ExpandMore } from "@mui/icons-material";
import { useState } from "react";

interface TrainingProps {
    callback: () => void;
    germanLanguage: boolean;
}

export const Training = ({ callback, germanLanguage }: TrainingProps) => {
    const [src, setSrc] = useState("https://maps.google.com/maps?q=totenweg,%2091522%20ansbach&t=k&z=13&ie=UTF8&iwloc=&output=embed")

    const changeSrc = () => {
        const herren = "https://maps.google.com/maps?q=totenweg,%2091522%20ansbach&t=k&z=13&ie=UTF8&iwloc=&output=embed";
        const youth = "https://maps.google.com/maps?q=Reuterstra%C3%9Fe%209,%2091522%20Ansbach&t=k&z=13&ie=UTF8&iwloc=&output=embed"

        if (src === herren) {
            setSrc(youth);
        } else {
            setSrc(herren);
        }
    };
    return (
        <>
            <Header callback={callback} germanLanguage={germanLanguage} />
            <StyledMenu>
                <div className="menu">
                    <OpeningHours germanLanguage={germanLanguage} />
                </div>

            </StyledMenu>
        </>
    );
};

const StyledMenu = styled.div`
    display: grid;
    justify-items: center;
    margin-top: 2rem;
    
    > div.menu > p > span {
        text-decoration: underline;
    }

    grid-template-rows: auto auto;
    gap: 5rem;

    div.wrapper {
        display: grid;
        text-align: center;
        grid-template-columns: auto 60rem auto;
        grid-template-rows: auto auto 2rem auto auto;
        grid-template-areas: "welcome welcome welcome" "subtitle subtitle subtitle" ". . ." "button button button" ". direction .";
        
        > div.direction {
            padding-top: 2rem;

            > div.acc {
                background: #182549;
                margin: 4rem 0;
                width: calc(100% + 6rem);
                margin-left: -3rem;
                border-radius: 0;
                box-shadow: 0 0.75rem 1rem rgba(0, 0, 0, .3);

                p.acc-text {
                    width: 100%;
                    text-align: center;
                    margin-left: 2.25rem;
                }

                div.googlemaps-wrapper {
                    position: relative;
                    margin: 1.5rem 0 3rem 0;
                    height: 35rem;
                    width: 100%;
        
                    > div.color {
                        position: absolute;
                        width: 50px;
                        height: 50px;
                        background: red;
                        z-index: 1;
                        border-radius: 1rem;
                        width: calc(100% - 1rem);
                        height: 100%;
                        background: linear-gradient(45deg, #182549 30%, #0f1831);
                        opacity: .25;
                        pointer-events: none;
                    }
                
                    > div.googlemaps-canvas {
                        overflow: hidden;
                        background: none!important;
                        height: 35rem;
                        width: calc(100% - 2rem);
                        margin-left: 1rem;
                        border-radius: 1rem;
                        z-index: 0;
                        transform: translateZ(0);
                        
                        > iframe#googlemaps-canvas {
                            height: 35rem;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
`;


/*

<div className="wrapper">
                    <div className="direction" style={{ gridArea: "direction" }}>
                        <Typography>{WelcomeTranslation.threeTimes[+germanLanguage]}</Typography>
                        <Typography>{WelcomeTranslation.our[+germanLanguage]} <span className="bold">{WelcomeTranslation.tackle[+germanLanguage]}</span> {WelcomeTranslation.prac[+germanLanguage]} <span className="bold">{WelcomeTranslation.when[+germanLanguage]}</span> {WelcomeTranslation.where[+germanLanguage]} <span className="bold">{WelcomeTranslation.youth[+germanLanguage]}</span> <span className="bold">{WelcomeTranslation.whenYouth[+germanLanguage]}</span> {WelcomeTranslation.whereYouth[+germanLanguage]}</Typography>
                        <Typography className="long">{WelcomeTranslation.bePart[+germanLanguage]}</Typography>

                        <Accordion className="acc">
                            <AccordionSummary expandIcon={<ExpandMore style={{ color: "white" }} />}>
                                <Typography className="acc-text">{WelcomeTranslation.location[+germanLanguage]} 📍 </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" marginTop="2rem">
                                    <Typography>{WelcomeTranslation.youthToggleOne[+germanLanguage]}<span className="bold">{WelcomeTranslation.youthToggleTwo[+germanLanguage]}</span></Typography>
                                    <Switch defaultChecked onChange={changeSrc} />
                                    <Typography>{WelcomeTranslation.tackleToggleOne[+germanLanguage]}<span className="bold">{WelcomeTranslation.tackleToggleTwo[+germanLanguage]}</span></Typography>
                                </Stack>

                                <div className="googlemaps-wrapper">
                                    <div className="color" />
                                    <div className="googlemaps-canvas">
                                        <iframe
                                            id="googlemaps-canvas"
                                            src={src}
                                            frameBorder="0"
                                            scrolling="no"
                                        />
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>

*/