import info from "../info/info.txt";
import { NewsType } from "../types/NewsType";

const readFile = (): Promise<NewsType[]> => {
    const newsTypes = fetch(info)
        .then(result => result.text()
            .then(input => parseFile(input)));

    return newsTypes;
};

const parseFile = (input: string): NewsType[] => {
    const news = input.split("::-::-");
    const mappedObjects = news.map(element => mapElementToObject(element));
    return mappedObjects;
};

const mapElementToObject = (element: string): NewsType => {
    const fields = element.split(";").map(el => el.replace(/^(\r\n)/,""));

    return {
        url: fields[0],
        imgName: fields[1],
        author: fields[2],
        heading: fields[3],
        text: fields[4],
    };
};

export const FileUtils = {
    readFile,
};