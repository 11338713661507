import styled from "@emotion/styled";
import { NewsType } from "../../types/NewsType";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Old from "../../images/news/681a9780-d334-4f9a-bf52-2b870d07d7e0.jpeg";

interface NewsPreviewProps {
    content: NewsType,
}

export const NewsPreview = ({content} : NewsPreviewProps) => {
    const navigate = useNavigate();
    
    return(
        <>
            <StyledNewsPreview onClick={() => navigate(`/news/${content.url}`)} className="news-prev">
                <Typography variant="h4" className="heading college" gridArea="heading">{content.heading}</Typography>
                <Typography variant="caption" gridArea="author" className="author">verfasst von: {content.author}</Typography>
                <div className="image" style={{backgroundImage: `url(${Old}`}} />
            </StyledNewsPreview>
        </>
    );
}

const StyledNewsPreview = styled.div`
    display: grid;
    width: 35rem;
    height: 25rem;

    border-radius: .5rem;
    box-shadow: 0.5rem 1rem 0.75rem rgba(0, 0, 0, .3);

    justify-items: center;
    background: rgba(35, 54, 110, 1);
    text-align: center;

    grid-template-rows: repeat(3, auto);
    grid-template-areas: "image" "heading" "author";

    > .author {
        color: rgba(255, 255, 255, .3) !important;
    }

    > .heading{
        font-size: 24px;
        width: 40ch;
    }

    > .image {
        grid-area: image;
        width: calc(100% - 5rem);
        height: 13rem;
        margin-top: 2.5rem;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        border-radius: .5rem;
        box-shadow: 0.5rem 1rem 0.75rem rgba(0, 0, 0, .3);
    }

    transform: scale(1);
    transition: transform .2s ease-in-out;

    &:hover{
        transform: scale(1.025);
        cursor: pointer;
    }
`;